import {useEffect, useState} from 'react';
import {getFailedLogins} from '../../../actions/dashboardActions';
import Table from '../../../components/modules/table';

const DashboardNotifications = () => {
    const [failedLogins, setFailedLogins] = useState<Array<Array<string>>>();

    useEffect(() => {
        getFailedLogins({
            limit: 100,
            page_number: 1,
            type: 'LOGIN_ERROR',
            from_date: "20-09-2021",
            to_date: "28-09-2021"
        }, (arr: Array<Array<string>>) => {
            setFailedLogins(arr);
        })
    }, []);

    return (
        <Table
            size=""
            title="Hatalı Girişlerim"
            headers={["Tarih"]}
            errorText="Herhangi bir hatalı girişiniz bulunmamaktadır."
            classNames="mobile-full"
            items={failedLogins}

        />
    )
}

export default DashboardNotifications;