import {Link} from "react-router-dom";
import {isExternal} from "util/types";

type ButtonProps = {
    text?: string,
    link?: string,
    classNames?: string,
    isExternal?: boolean
}
const Button = (props: ButtonProps) => {
    if (props.text && props.text.length > 0 && props.link && props.link.length > 0) {
        return props.isExternal ? <Link className={"btn " + props.classNames ?? ""} to=""
                                        onClick={() => window.location.assign("https://srtestapp.yemekpay.com/register")}>{props.text}</Link>
            : <Link className={"btn " + props.classNames ?? ""} to={props.link}>{props.text}</Link>
    }

    return (null);
}

export default Button;