import React, {useEffect, useState} from 'react';
import './style.css'
import {
    createTheme,
    FormControlLabel,
    Switch,
    ThemeProvider
} from "@mui/material";
import {useCookies} from "react-cookie";
import ReactGA from 'react-ga';

function MainCookieConsent() {
    const [cookies, setCookie] = useCookies(["cookie-consent"]);
    const [functional, setFunctional] = useState(false);
    const [mandatory, setMandatory] = useState(true);
    const [cookieAccepted, setCookieAccepted] = useState(true);

    useEffect(() => {
        setCookieAccepted(cookies["cookie-consent"]);
    })

    function acceptAll() {
        const cookieConsent = {
            functional: true,
            mandatory: true,
            secure: true,
        };
        setCookie("cookie-consent", cookieConsent, {path: "/", secure: true});
        ReactGA.event({
            category: 'Cookie Consent',
            action: 'All Cookies Accepted',
        });
    }

    function acceptSelected() {
        const cookieConsent = {
            functional: functional,
            mandatory: mandatory,
            secure: true,
        }
        setCookie("cookie-consent", cookieConsent, {path: "/", secure: true});
    }

    function rejectAllExceptMandatory() {
        const cookieConsent = {
            functional: false,
            mandatory: true,
            secure: true,
        }
        setCookie("cookie-consent", cookieConsent, {path: "/", secure: true});
    }

    const theme = createTheme({
        components: {
            MuiSwitch: {
                styleOverrides: {
                    switchBase: {
                        color: "white",
                    },
                    colorPrimary: {
                        "&.Mui-checked": {
                            color: "#FA0050",
                        },
                        "&.Mui-unchecked": {
                            color: "white",
                        },
                    },
                    track: {
                        opacity: 0.2,
                        backgroundColor: "#FA0050",
                        ".Mui-checked.Mui-checked + &": {
                            opacity: 0.7,
                            backgroundColor: "#FA0050",
                        },
                    },
                },
            },
        },
    });


    return (
        <ThemeProvider theme={theme}>
            <div className="cookie-container" hidden={cookieAccepted}>
                <div className="cookie-title">Ayarlarınızı Düzenleyin</div>
                <div className="cookie-text">
                    <div>Şirketimiz, sizlere mümkün olan en iyi online ödeme deneyimini yaşatmak için web sitesinde
                        Çerez (Cookie) kullanmaktadır. Sanal dünyada hemen her web sitesinde kullanılan çerezler, web
                        sitemizi ziyaret ettiğinizde cihazınıza eklenen ve bilgi içeren küçük elektronik tanımlama
                        dosyalarıdır. Çerezler yoluyla internet sitemizden en verimli şekilde faydalanabilmeniz ve
                        kullanıcı deneyiminizi geliştirebilmek amaçlanmaktadır. <a
                            href="/cerez-ayarlari-ve-cerez-aydinlatma.pdf" title="KVKK Aydınlatma metni"
                            target="_blank"><b> Çerez Aydınlatma Metnimiz </b></a> için lütfen tıklayınız.
                    </div>
                </div>

                <div style={{flexDirection: "column"}}>
                    <FormControlLabel
                        control={
                            <Switch onChange={(e) => {
                                setFunctional(e.target.checked)
                            }} checked={functional}/>
                        }
                        labelPlacement={"start"}
                        label={<div style={{fontSize: 15}}>Fonksiyonel ve Analitik Çerezler </div>}
                    />

                    <FormControlLabel
                        control={
                            <Switch checked={mandatory} style={{color: "#FA0050"}} onChange={(e) => {
                                setMandatory(e.target.checked)
                            }} disabled/>
                        }
                        labelPlacement={"start"}
                        label={<div style={{fontSize: 15}}>Zorunlu Çerezler</div>}
                    />
                </div>

                <div className="cookie-buttons">
                    <button className="cookie-button" onClick={rejectAllExceptMandatory}>Reddet</button>
                    <button className="cookie-button" onClick={acceptSelected}>Ayarları Kaydet</button>
                    <button className="cookie-button" onClick={acceptAll}>Hepsini Kabul Et</button>
                </div>
            </div>
        </ThemeProvider>
    );
}


export default MainCookieConsent;