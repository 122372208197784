import React, {useState, useEffect} from "react";
import {FormModel, ValidationType, FormItemType, FormWrapperModel} from "./models/types";
import InputEmail from "./items/input-email";
import InputText from "./items/input-text";
import Textarea from "./items/textarea";
import './form.scss';
import Dropdown from "./items/dropdown";
import InputPassword from "./items/input-password";
import {validateButtons, validateFormItem, validateFormModel} from "./models/validations";
import InputSplit from "./items/input-split";
import Checkbox from "./items/checkbox";
import Radio from "./items/radio";
import FileUpload from "./items/file-upload";
import InputDate from "./items/input-date";
import InputNumber from "./items/input-number";
import ReCAPTCHA from "react-google-recaptcha";

const TEST_SITE_KEY = "6LdO2b4kAAAAAGWDcMDjqCbp4KMGi5KKnVhCfOtU";
// site-key= 6LdO2b4kAAAAAGWDcMDjqCbp4KMGi5KKnVhCfOtU
// secret-key= 6LdO2b4kAAAAAIxfMFqfbpKOBfbONHb6-0n1LhpI
// const TEST_SITE_KEY = "XXX";

const Form = (props: FormWrapperModel) => {
    const {formModel, submitFunction, errorFunction} = props;
    const [model, setModel] = useState<FormModel>(formModel);

    useEffect(() => {
        if (validateButtons(model)) {
            setModel({...model});
        }
    }, [model])

    useEffect(() => {
        const {formModel} = props;
        setModel(formModel);
    }, [props]);

    const handleChange = (value: any, name: string) => {
        let item = model.items.find((item) => item.name === name);

        if (item) {
            item.value = value;
            item = validateFormItem(item, model);
        }

        setModel({...model});

        if (item?.changeFunction) {
            item.changeFunction(item);
        }
    }

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();

        var validatedModel = validateFormModel(model);

        setModel({...validatedModel});

        let isValid = validatedModel.items.every((item, index) => {
            return item.type === FormItemType.CustomHtml || item.type === FormItemType.Button || item.isValid === true;
        });

        if (isValid) {
            let formModel: any = {};

            model.items.forEach((item, index) => {
                if (item.type !== FormItemType.CustomHtml && item.type !== FormItemType.Button) {
                    formModel[item.name] = item.value;
                }
            });

            if (submitFunction) {
                submitFunction(formModel);
            }
        } else if (errorFunction) {
            errorFunction(validatedModel);
        }
    }

    return (
        <form className={"general" + (formModel.classNames ? " " + formModel.classNames : "")} onSubmit={handleSubmit}
              noValidate>
            {model.items.map((item, index) => {
                if (item.type === FormItemType.Text) {
                    return (
                        <InputText key={"form-input-" + index} item={item} changeFunction={handleChange}/>
                    )
                } else if (item.type === FormItemType.Number) {
                    return (
                        <InputNumber key={"form-input-" + index} item={item} changeFunction={handleChange}/>
                    )
                } else if (item.type === FormItemType.Email) {
                    return (
                        <InputEmail key={"form-input-" + index} item={item} changeFunction={handleChange}/>
                    )
                } else if (item.type === FormItemType.Password) {
                    return (
                        <InputPassword key={"form-input-" + index} item={item} changeFunction={handleChange}/>
                    )
                } else if (item.type === FormItemType.SplitInput) {
                    return (
                        <InputSplit key={"form-input-" + index} item={item} changeFunction={handleChange}/>
                    )
                } else if (item.type === FormItemType.Textarea) {
                    return (
                        <Textarea key={"form-input-" + index} item={item} changeFunction={handleChange}/>
                    )
                } else if (item.type === FormItemType.Select) {
                    return (
                        <Dropdown key={"form-input-" + index} item={item} changeFunction={handleChange}/>
                    )
                } else if (item.type === FormItemType.Checkbox) {
                    return (
                        <Checkbox key={"form-input-" + index} item={item} changeFunction={handleChange}/>
                    )
                } else if (item.type === FormItemType.Radio) {
                    return (
                        <Radio key={"form-input-" + index} item={item} changeFunction={handleChange}/>
                    )
                } else if (item.type === FormItemType.File) {
                    return (
                        <FileUpload key={"form-input-" + index} item={item} changeFunction={handleChange}/>
                    )
                } else if (item.type === FormItemType.Date) {
                    return (
                        <InputDate key={"form-input-" + index} item={item} changeFunction={handleChange}/>
                    )
                } else if (item.type === FormItemType.Button) {
                    return (
                        <div key={"form-input-" + index}
                             className={"form-btn-container" + (item.containerClassNames ? " " + item.containerClassNames : "")}>
                            <button className={"btn" + (item.classNames ? " " + item.classNames : "")}
                                    type={item.name === "submit" ? "submit" : "button"} title={item.label}
                                    onClick={() => {
                                        if (item.changeFunction) item.changeFunction()
                                    }}
                                    disabled={((item.rules && item.rules.some(x => x.type === ValidationType.AllValidated) && !item.isValid) || (item.isValid === false) ? true : false)}>{item.label}</button>
                        </div>
                    )
                } else if (item.type === FormItemType.ReCaptcha) {
                    return (<div style={{marginTop: "25px", justifyContent: "center", display: "flex"}}><ReCAPTCHA
                        sitekey={TEST_SITE_KEY}
                        onChange={(captcha) => {
                            handleChange(captcha, "re-captcha")
                        }}
                        onExpired={() => {
                            handleChange("", "re-captcha")
                        }}
                        onErrored={() => {
                            handleChange("", "re-captcha")
                        }}

                    /></div>)
                } else if (item.type === FormItemType.CustomHtml) {
                    return item.value
                }

                return null;
            })}
        </form>
    )
}

export default Form;