import {ReactNode, useEffect} from "react";
import {Link} from "react-router-dom";
import {logout} from "../../../actions/authenticationActions";
import {getUserProfile} from "../../../actions/dashboardActions";
import logo from '../../../assets/Icons/logo.svg';
import {getUserInfo, setUserInfo} from "../../../store/globalSlice";
import {useAppSelector} from "../../../store/hooks";
import store from "../../../store/store";
import Image from '../../modules/image';

type OnboardingLayoutProps = {
    children: Array<ReactNode>
}

const OnboardingLayout = (props: OnboardingLayoutProps) => {
    const userInfo = useAppSelector(getUserInfo);

    const logoutClick = (event: React.MouseEvent) => {
        logout();
    }

    useEffect(() => {
        if (!userInfo) {
            getUserProfile((response: any) => {
                store.dispatch(setUserInfo(response.payload));
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <header>
                <div className="container">
                    <Link to="/">
                        <img className="logo" src={logo} alt=""/>
                    </Link>
                    <button className="btn-logout" type="button" onClick={logoutClick}>
                        <Image src={require("../../../assets/Icons/logout.svg").default}/>
                        <span>Çıkış Yap</span>
                    </button>
                </div>
            </header>
            {props.children}
        </>

    )
}

export default OnboardingLayout;