import {FormItem} from "../models/types";
import ErrorMessage from './errorMessage';

type CheckboxProps = {
    item: FormItem,
    changeFunction: Function
}

const Checkbox = (props: CheckboxProps) => {
    let disabled: any = {};

    if (props.item.isDisabled) {
        disabled["disabled"] = "disabled";
    }

    return (
        <div
            className={"form-item" + (props.item.value.length > 0 ? " active" : "") + (props.item.isValid === false ? " has-error" : "") + (props.item.classNames ? " " + props.item.classNames : "")}>
            <input
                type="checkbox"
                id={props.item.name}
                name={props.item.name}
                defaultValue={props.item.value}
                onChange={(e) => {
                    props.changeFunction(e.target.checked, props.item.name)
                }}
                {...disabled}
            />
            <label className="button s1" htmlFor={props.item.name}>{props.item.label}</label>
            <ErrorMessage rules={props.item.rules}/>
        </div>
    )
}

export default Checkbox;