import {FormItem} from "../models/types";
import ErrorMessage from './errorMessage';
import IndividualMessage from './individualMessage';
import NumberFormat from 'react-number-format';
import {preventKey} from "../models/preventions";

type InputNumberProps = {
    item: FormItem,
    changeFunction: Function
}

const InputNumber = (props: InputNumberProps) => {
    let disabled: any = {};

    if (props.item.isDisabled) {
        disabled["disabled"] = "disabled";
    }

    return (
        <div
            className={"form-item" + ((props.item.value.toString()).length > 0 ? " active" : "") + (props.item.isValid === false ? " has-error" : "") + (props.item.classNames ? " " + props.item.classNames : "")}>
            <NumberFormat
                className="button s1"
                name={props.item.name}
                value={props.item.value}
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={2}
                fixedDecimalScale={true}
                suffix="TL"
                // onChange={(e) => { props.changeFunction(e.target.value, props.item.name) }}
                onValueChange={(e) => {
                    props.changeFunction(e.value, props.item.name)
                }}
                {...disabled}
            />
            <label className="button s2">{props.item.label}</label>
            <ErrorMessage rules={props.item.rules}/>
            <IndividualMessage rules={props.item.rules}/>
        </div>
    )
}

export default InputNumber;