import React from "react";
import logo from "../../../assets/Icons/logo.svg";
import pcidss_footer from "../../../assets/Icons/pcidss-footer.svg";
import ssl_footer from "../../../assets/Icons/ssl-footer.svg";
import tcmb_footer from "../../../assets/Icons/tcmb-footer.svg";
import Image from "../../modules/image";
import Text from "../../modules/text";
import "./footer.scss";

const Footer = () => {
    const cerezAyarlariVeCerezAydinlatma = {
        title: "Çerez Ayarları ve Çerez Aydınlatma Metni",
        href: "/cerez-ayarlari-ve-cerez-aydinlatma.pdf",
    };

    const sozlesmeler = {
        title: "Sözleşmeler",
        href: "/sozlesmeler.pdf",
    };

    const kvkkPolitikasi = {
        title: "KVKK Politikası",
        href: "/kvkk-politikasi.pdf",
    };

    const todebBireyselMusteriHakemHeyeti = {
        title: "TÖDEB Bireysel Müşteri Hakem Heyeti",
        href: "/todeb-bireysel-musteri-hakem-heyeti.pdf",
    };

    const kullanicilarinBilgilendirmesi = {
        title: "Kullanıcıların Bilgilendirmesi",
        href: "/kullanicilarin-bilgilendirmesi.pdf",
    };

    const bilgiToplumuHizmetleri = {
        title: "Bilgi Toplumu Hizmetleri",
        href: "https://e-sirket.mkk.com.tr/esir/Dashboard.jsp#/sirketbilgileri/21267",
    };

    return (
        <footer>
            <div className="container" style={{lineHeight: "0.3rem"}}>
                <div className="yemekpaylogo">
                    <Image src={logo} classNames="logo"></Image>
                    <Text size={3}>
                        © 2024 YemeksepetiPay All rights reserved.
                    </Text>
                </div>

                <div className="menu" style={{width: "55%", textAlign: "center"}}>
                    <ul>
                        <li>
                            <a
                                className="text s5"
                                title={cerezAyarlariVeCerezAydinlatma.title}
                                href={cerezAyarlariVeCerezAydinlatma.href}
                                target="_blank"
                                data-test-id="gizlilik-ve-cerez-politikasi-link"
                            >
                                {cerezAyarlariVeCerezAydinlatma.title}
                            </a>
                        </li>

                        <li>
                            <a
                                className="text s5"
                                title={sozlesmeler.title}
                                href={sozlesmeler.href}
                                target="_blank"
                                data-test-id="sozlesmeler-link"
                            >
                                {sozlesmeler.title}
                            </a>
                        </li>

                        <li>
                            <a
                                className="text s5"
                                title={kvkkPolitikasi.title}
                                href={kvkkPolitikasi.href}
                                target="_blank"
                                data-test-id="kvkk-politikasi-link"
                            >
                                {kvkkPolitikasi.title}
                            </a>
                        </li>

                        <li>
                            <a
                                className="text s5"
                                title={todebBireyselMusteriHakemHeyeti.title}
                                href={todebBireyselMusteriHakemHeyeti.href}
                                target="_blank"
                                data-test-id="todeb-bireysel-musteri-hakem-heyeti-link"
                            >
                                {todebBireyselMusteriHakemHeyeti.title}
                            </a>
                        </li>

                        <li>
                            <a
                                className="text s5"
                                title={kullanicilarinBilgilendirmesi.title}
                                href={kullanicilarinBilgilendirmesi.href}
                                target="_blank"
                                data-test-id="kullanicilarin-bilgilendirmesi-link"
                            >
                                {kullanicilarinBilgilendirmesi.title}
                            </a>
                        </li>

                        <li>
                            <a
                                className="text s5"
                                title={bilgiToplumuHizmetleri.title}
                                href={bilgiToplumuHizmetleri.href}
                                target="_blank"
                                data-test-id="bilgi-toplumu-hizmetleri-link"
                            >
                                {bilgiToplumuHizmetleri.title}
                            </a>
                        </li>

                    </ul>
                </div>

                <div className="logo-div-1">
                    <img style={{
                        width: '125px',
                        height: '60px',
                        borderRadius: "0.06rem",
                        margin: '3px',
                    }} src={pcidss_footer} className="logo-pcidss" alt={"PCIDSS Logo"}></img>
                </div>

                <div className="logo-div-2">
                    <img style={{
                        width: '125px',
                        height: '60px',
                        margin: '3px',
                    }} src={ssl_footer} className="logo-ssl" alt={"SSL Logo"}></img>
                </div>

                <div className="logo-div-4">
                    <img style={{
                        width: '175px',
                        height: '60px',
                        margin: '3px',
                        backgroundColor: "#293057",
                    }} src={tcmb_footer} className="logo-tcmb" alt={"TCMB Logo"}></img>
                </div>
            </div>
        </footer>
    );
}

export default Footer;