import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import type {RootState} from './store'

type UserInfo = {
    userId: string,
    email: string,
    firstName: string,
    lastName: string,
    mobileNumber: string
}

type CompanyInfo = {
    birthdate: string,
    cityCode: string,
    cityName: string,
    companyName: string,
    companyTypeCode: number,
    contactId: string,
    countyCode: string,
    countyName: string,
    creationSourceCode: number,
    districtCode: string,
    districtName: string,
    documentList: any,
    email: string,
    fax: string,
    firstname: string,
    foreignerIdentificationNumber: string,
    iban: string,
    isAcceptedContract: boolean,
    isOnboarded: boolean,
    jobTitle: string,
    kvkkPermissionCode: number,
    lastUpdateSourceCode: number,
    lastname: string,
    mainYemekPayStatusCode: number,
    mobilePhone: string,
    nationalityCode: string,
    nationalityName: string,
    openAddress: string,
    operationOfArea: string,
    sapId: string,
    subYemekPayStatusCode: number,
    tckn: string,
    taxNumber: string,
    title: string,
    tradeRegisterNumber: string
}

type MessageType = {
    title?: string,
    text?: string,
    image?: string,
    isMobileFull?: boolean,
    btnPrimaryText?: string,
    btnPrimaryClassNames?: string,
    btnPrimaryCallback?: Function,
    btnSecondaryText?: string,
    btnSecondaryCallback?: Function,
    btnCloseCallback?: Function,
    classNames?: string
}

interface GlobalState {
    loadingCount: number,
    message?: MessageType,
    user?: UserInfo,
    company?: CompanyInfo
}

const initialState: GlobalState = {
    loadingCount: 0,
    message: undefined,
    user: undefined,
    company: undefined
}

export const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        incrementLoadingCount: (state) => {
            state.loadingCount += 1;
        },
        decrementLoadingCount: (state) => {
            state.loadingCount -= 1
        },
        showMessage: (state, action: PayloadAction<MessageType>) => {
            state.message = action.payload;
        },
        hideMessage: (state) => {
            state.message = undefined;
        },
        setUserInfo: (state, data: any) => {
            state.user = data.payload;
        }
        ,
        setCompanyInfo: (state, data: any) => {
            state.company = data.payload;
        }
    },
})

export const {
    incrementLoadingCount,
    decrementLoadingCount,
    showMessage,
    hideMessage,
    setUserInfo,
    setCompanyInfo
} = globalSlice.actions

export const getLoadingCount = (state: RootState) => state.global.loadingCount;
export const getMessage = (state: RootState) => state.global.message;
export const getUserInfo = (state: RootState) => state.global.user;
export const getCompanyInfo = (state: RootState) => state.global.company;

export default globalSlice.reducer