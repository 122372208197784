import {format} from 'date-fns';
import {useEffect, useState} from 'react';
import {getTransactions} from '../../../actions/dashboardActions';
import Card from '../../../components/modules/card';
import Table from '../../../components/modules/table';
import {getCompanyInfo} from '../../../store/globalSlice';
import {useAppSelector} from '../../../store/hooks';
import tr from "date-fns/locale/tr";
import CookieCommonConsent from "../../../components/common/cookie-consent";

const DashboardFinancial = () => {
    let now = new Date();
    const [balance, setBalance] = useState<string>("0");
    const [transactions, setTransactions] = useState<Array<Array<string>>>();
    const companyInfo = useAppSelector(getCompanyInfo);

    useEffect(() => {
        getTransactions({
            restaurantId: companyInfo?.sapId,
            recordsPerPage: 10,
            pageNumber: 1,
            showActivities: true,
            documentDateStart: format(now.setDate(now.getDate() - 30), "yyyy-MM-dd"),
            documentDateEnd: format(new Date(), "yyyy-MM-dd")
        }, (arr: Array<Array<string>>, balance: string) => {
            setTransactions(arr);
            setBalance(balance);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="card-container">
                <Card
                    size="small"
                    title="Güncel Bakiyem "
                    text={format(new Date(), "dd MMMM yyyy", {locale: tr}) + " 1 gün önceki bakiyenizi göstermektedir."}
                    price={balance + " TL"}
                    classNames="light"
                />
                <Card
                    size="large"
                    title="Para Çekme Talebi"
                    text="YemeksepetiPay korumalı hesaplarında biriken bakiyenizi “Para Çekme Talebi” oluşturarak banka hesabınıza aktarabilirsiniz."
                    buttonText="Bakiye Transferi Talebi Oluştur"
                    buttonUrl="/dashboard/financial/payout"
                />
            </div>
            <Table
                size="full"
                title="Son İşlemlerim"
                detailText="Tüm İşlem Hareketlerini Göster"
                detailUrl="/dashboard/transactions"
                headers={["Tarih", "ID", "Tutar", "İşlem Tipleri"]}
                errorText="Bu alan şu anda görüntülenememektedir. Daha sonra tekrar deneyiniz."
                items={transactions}
            />


        </>
    )
}

export default DashboardFinancial;