import './about.scss';
import Title from '../../modules/title';
import CookieCommonConsent from "../cookie-consent";

const About = () => {

    return (
        <section className="about">
            <div className="container large">
                <div className='banner'>
                    <img className='banner-img' src={require('../../../assets/Uploads/about.png')} alt={"about"}/>
                    <div className="banner-text">Hakkımızda</div>
                </div>
                <div className="about-container">
                    <div className="text s1" style={{paddingTop: '0.3rem'}}>
                        <p>
                            Yemekpay Elektronik Para ve Ödeme Hizmetleri A.Ş. “Yemeksepetipay”, 26/11/2020 tarihinde
                            tescil ve ilan edilmesi ile DH FINANCIAL SERVICES HOLDING GmbH’in %100 iştiraki olarak
                            İstanbul’da kurulmuştur.
                        </p>
                        <p>
                            <span
                                style={{fontWeight: 'bold'}}>Delivery Hero Financial Services Holding GmbH;</span> Delivery
                            Hero SE iştiraki olup, 72 ülkede e-ticaret ve ödeme sistemleri konusunda hizmet veren bir
                            ekosistemin parçasıdır.
                        </p>
                        <p>
                            <span style={{fontWeight: 'bold'}}>Yemeksepeti ise,</span> Ana ortağımız Delivery Hero SE
                            iştiraki olup, Yemek Sepeti Elektronik İletişim Perakende Gıda Lojistik A.Ş. olarak 20 yılı
                            aşkındır e-ticaret sektöründe faaliyet göstermekte olan grup şirketimizdir. Yıllar
                            içerisinde ekosistemindeki tüm paydaşlarına sağladığı yenilikçi, kullanıcı dostu çözümleri
                            ile Türkiye halkının yoğun olarak kullandığı uygulamalardan birinin sahibi haline gelmiştir.
                        </p>
                        <p>
                            Biz de <span style={{fontWeight: 'bold'}}>Yemeksepetipay</span> olarak, gerek örnek
                            uygulamalarımız ve tasarlanmış kullanıcı dostu süreçlerimiz, gerekse müşteri odaklı bakış
                            açımızla bir yandan parçası olduğumuz Fintek grubuna dahil olan diğer ülkelerdeki kuruluşlar
                            için de birer örnek uygulama olmayı hedeflerken, bir yandan da Yemeksepeti’nin Restoran Üye
                            İş Yerleri ile Kullanıcıları da dahil olmak üzere Sanal POS ve Elektronik Para Cüzdan
                            Hizmetlerinin tamamını Siz Değerli müşterilerimizin hizmetine sunacak olmaktan büyük heyecan
                            duyuyoruz.
                        </p>
                        <p>
                            Yemekpay Elektronik Para ve Ödeme Hizmetleri A.Ş, faaliyet iznini, TCMB Banka Meclisi’nin 22
                            Aralık 2023 tarih ve 11567/21166 sayılı kararı ile 31/01/2024 tarihi itibari ile almıştır ve
                            sizlere en iyi hizmeti sağlamak için hazırlık çalışmalarına devam etmektedir.
                        </p>
                    </div>
                </div>
            </div>

            <div className="container large" style={{paddingTop: '0.3rem'}}>
                <div className="about-container">

                    <Title size={4}>
                        Yemekpay Elektronik Para ve Ödeme Hizmetleri A.Ş.
                    </Title>

                    <div className="text s1 row" style={{paddingTop: '0.3rem'}}>

                        <div className='column' style={{paddingRight: '0.4rem'}}>
                            <Title size={5}>Adres</Title>
                            <p>Esentepe Mah. Dede Korkut Sk. Ferko Signature Blok.
                                No: 28/11 Şişli, İstanbul</p>
                            <Title size={5}>Telefon Numarası</Title>
                            <p>0212 274 18 00</p>
                            <Title size={5}>Ticari Sicil Numarası</Title>
                            <p>279016-5</p>

                            <Title size={5}>Mersis No</Title>
                            <p>0947091666800001</p>
                        </div>
                        <div className='column' style={{paddingLeft: '0.4rem', paddingRight: '0.4rem'}}>
                            <Title size={5}>Düzenleyici ve Denetleyici Otorite</Title>
                            <p>Türkiye Cumhuriyet Merkez Bankası (TCMB)</p>
                            <img className='banner-img' style={{maxWidth: '70%', paddingBottom: '0.2rem'}}
                                 src={require('../../../assets/Uploads/tcmb.png')} alt={"tcmb"}/>

                            <Title size={5}>Adres</Title>
                            <p>Hacı Bayram Mah. İstiklal Cad. No: 10 06050 Ulus
                                Altındağ/Ankara
                            </p>
                        </div>
                    </div>
                </div>
            </div>


        </section>
    )
}

export default About;