import {preventKey} from '../models/preventions';
import {FormItem, ValidationType} from "../models/types";
import ErrorMessage from './errorMessage';
import IndividualMessage from './individualMessage';

type TextareaProps = {
    item: FormItem,
    changeFunction: Function
}

const Textarea = (props: TextareaProps) => {
    const maxLength = props.item.rules?.find(x => x.type === ValidationType.MaxLength)?.value;

    let disabled: any = {};

    if (props.item.isDisabled) {
        disabled["disabled"] = "disabled";
    }

    return (
        <div
            className={"form-item" + (props.item.isValid === false ? " has-error" : "") + (props.item.classNames ? " " + props.item.classNames : "")}>
            <textarea
                className="text s2"
                placeholder={props.item.label}
                name={props.item.name}
                defaultValue={props.item.value}
                onKeyPress={(e) => preventKey(e, props.item.prevention)}
                onChange={(e) => {
                    props.changeFunction(e.target.value, props.item.name)
                }}
                {...disabled}
            ></textarea>
            <ErrorMessage rules={props.item.rules}/>
            {maxLength && maxLength > 0 &&
                <div className="text s4 letter-count">{props.item.value.length} / {maxLength}</div>
            }
            <IndividualMessage rules={props.item.rules}/>
        </div>
    )
}

export default Textarea;