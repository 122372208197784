import {Route, useLocation} from "react-router-dom";
import Dashboard from "../components/layout/dashboard";
import Footer from "../components/layout/footer";
import Header from "../components/layout/header";
import OnboardingLayout from "../components/layout/onboarding";
import RegisterPage from "../pages/authentication/register";
import SignUpPage from "../pages/authentication/signup";
import DashboardCompany from "../pages/dashboard/company";
import DashboardContact from "../pages/dashboard/contact";
import DashboardFinancial from "../pages/dashboard/financial";
import DashboardPayout from "../pages/dashboard/financial/payout";
import DashboardHome from "../pages/dashboard/home";
import DashboardNotifications from "../pages/dashboard/notifications";
import DashboardProfile from "../pages/dashboard/profile";
import DashboardTransactions from "../pages/dashboard/transactions";
import HomePage from "../pages/home";
import OnboardingFormPage from "../pages/onboarding/form";
import OnboardingPage from "../pages/onboarding/home";
import ContactPage from "../pages/static/contact";
import PricesPage from "../pages/static/prices";
import AboutPage from "../pages/static/about";

function MainRouter() {
    const location = useLocation();

    if (location.pathname.indexOf("dashboard") !== -1) {
        return (
            <Dashboard>
                <Route path="/dashboard" exact component={DashboardHome}/>
                <Route path="/dashboard/notifications" exact component={DashboardNotifications}/>
                <Route path="/dashboard/financial" exact component={DashboardFinancial}/>
                <Route path="/dashboard/financial/payout" exact component={DashboardPayout}/>
                <Route path="/dashboard/transactions" exact component={DashboardTransactions}/>
                <Route path="/dashboard/company" exact component={DashboardCompany}/>
                <Route path="/dashboard/contact" exact component={DashboardContact}/>
                <Route path="/dashboard/profile" exact component={DashboardProfile}/>
            </Dashboard>
        )
    } else if (location.pathname.indexOf("onboarding") !== -1) {
        return (
            <OnboardingLayout>
                <Route path="/onboarding" exact component={OnboardingPage}/>
                <Route path="/onboarding-form" exact component={OnboardingFormPage}/>
                <Route path="/onboarding/application-evaluation" exact component={OnboardingPage}/>
                <Route path="/onboarding/incomplete-application" exact component={OnboardingPage}/>
            </OnboardingLayout>
        )
    } else {
        return (
            <>
                <Header/>
                <Route path={["/home", "/"]} exact component={HomePage}/>
                <Route path="/prices" exact component={PricesPage}/>
                <Route path="/about" exact component={AboutPage}/>
                <Route path="/register" exact component={RegisterPage}/>
                <Route path="/signup" exact component={SignUpPage}/>
                <Route path="/contact" exact component={ContactPage}/>
                <Footer/>
            </>
        )
    }
}

export default MainRouter;