import {Link} from "react-router-dom";
import Form from "../form";
import {FormModel} from "../form/models/types";
import Image from "./image";
import Text from "./text";
import Title from "./title";

type TableProps = {
    title?: string,
    detailText?: string,
    detailUrl?: string,
    size: "" | "full"
    headers: Array<string>,
    items?: Array<Array<string>>,
    filterForm?: FormModel,
    errorText?: string,
    classNames?: string,
}

const Table = (props: TableProps) => {
    return (
        <div
            className={"table " + props.size + (props.classNames ? " " + props.classNames : "") + (props.items && props.items.length ? " link-padding" : "")}>
            {props.detailText && props.detailUrl && props.items && props.items.length > 0 &&
                <Link to={props.detailUrl} className="detail-link">
                    <span className="button s1">{props.detailText}</span>
                    <Image src={require("../../assets/Icons/arrow-right-gray.svg").default}></Image>
                </Link>
            }
            {props.filterForm &&
                <Form key={"table-filter"} formModel={props.filterForm}/>
            }
            <table>
                <thead>
                <tr>
                    <td colSpan={2} className="title s4">{props.title}</td>
                </tr>
                <tr>
                    {props.headers.map((item, index) => (
                        <td style={{backgroundColor: '#F3F3F3'}} key={"table-head-" + index} className="title s6"><span
                            style={{color: '#9498B8'}}> {item} </span></td>
                    ))}
                </tr>
                </thead>
                {props.items && props.items.length > 0 &&
                    <tbody>
                    {props.items.map((row, rowIndex) => (
                        <tr key={"table-row-" + rowIndex}>
                            {row.map((item, index) => (
                                <td key={"table-cell-" + index} className="text s5">{item}</td>
                            ))}
                        </tr>
                    ))}
                    </tbody>
                }
            </table>
            {(!props.items || props.items.length === 0) && props.errorText &&
                <div className="error">
                    <Text size={5}>
                        {props.errorText}
                    </Text>
                </div>
            }
        </div>
    )
}

export default Table;