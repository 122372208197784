import Button from '../../modules/button';
import Image from '../../modules/image';
import List from '../../modules/list';
import PreTitle from '../../modules/pretitle';
import Title from '../../modules/title';
import Text from '../../modules/text';
import './main-content.scss';

type MainContentProps = {
    image?: string,
    preTitle?: string,
    title: string,
    text?: string,
    list?: Array<string>,
    dotsDireciton: boolean,
    btnPrimaryText?: string,
    btnSecondaryText?: string,
    btnPrimaryLink?: string,
    btnSecondaryLink?: string,
    isExternal?: boolean
}

const defaultProps: MainContentProps = {
    title: "Title Required",
    dotsDireciton: true,
}

const MainContent = (props: MainContentProps) => {
    return (
        <section className="main-content">
            <div className="container">
                <Image src={props.image} containerClass={"image" + (!props.dotsDireciton ? " dot-bottom" : "")}></Image>
                <div className="content">
                    <PreTitle>
                        {props.preTitle}
                    </PreTitle>
                    <Title size={1}>
                        {props.title}
                    </Title>
                    <Text size={1}>
                        {props.text}
                    </Text>
                    <List items={props.list}></List>
                    <Button text={props.btnPrimaryText} isExternal={props.isExternal} link={props.btnPrimaryLink}
                            classNames="large secondary"></Button>
                    <Button text={props.btnSecondaryText} link={props.btnSecondaryLink}
                            classNames="large tertiary"></Button>
                </div>
            </div>
        </section>
    )
}

MainContent.defaultProps = defaultProps;

export default MainContent;