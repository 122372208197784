import React, {useEffect, useState} from 'react';
import './signup.scss';
import Form from '../../../components/form';
import {
    FormItem,
    FormItemOption,
    FormItemType,
    FormModel,
    PreventionType,
    ValidationType
} from '../../../components/form/models/types';
import Title from '../../../components/modules/title';
import Text from '../../../components/modules/text';
import Image from '../../../components/modules/image';
import Button from '../../../components/modules/button';
import Sms from '../../../components/common/smsconfirm';
import {register} from '../../../actions/authenticationActions';
import CookieCommonConsent from '../../../components/common/cookie-consent';
import {useAppDispatch} from '../../../store/hooks'
import {hideMessage, showMessage} from '../../../store/globalSlice'
import {convertToNaceCode,} from '../../../util/jsonConvertor';
import {type} from 'os';

const SignUpPage = () => {
    var dispatch = useAppDispatch();
    const [isPopupShowed, setPopupShowed] = useState(true);
    const [companyPartnerStatus, setCompanyPartnerStatus] = useState<boolean>(false);

    const companyPartnerQuestions = Array<FormItemOption>()
    companyPartnerQuestions.push({value: "HAYIR", label: "HAYIR"})
    companyPartnerQuestions.push({value: "EVET", label: "EVET"})

    const paymentMethods = Array<FormItemOption>()
    paymentMethods.push({value: "Sanal Pos (Ertesi Gün)", label: "Sanal Pos (Ertesi Gün)"})
    paymentMethods.push({value: "Sanal Pos (7 Gün)", label: "Sanal Pos (7 Gün)"})
    paymentMethods.push({value: "Sanal Pos (21 Gün)", label: "Sanal Pos (21 Gün)"})

    const [firstStepModel] = useState<any>(null);
    const [setSecondStepModel] = useState<any>(null);
    let requiredFieldsForCompanyPartners = Array<String>(
        "partner1Name",
        "partner1Share",
        "partner2Name",
        "partner2Share"
    )
    let otherFieldsForCompanyPartners = Array<String>(
        "partner3Name",
        "partner3Share",
        "partner4Name",
        "partner4Share",
        "partner5Name",
        "partner5Share",
    )

    const [formModel, setFormModel] = useState<FormModel>({
        items: [
            {
                type: FormItemType.Text,
                name: "merchantName",
                value: "",
                label: "Üye İşyeri Adı*",
                rules: [
                    {type: ValidationType.Required},
                    {
                        type: ValidationType.MaxLength,
                        value: 64,
                        message: "*Maksimum 64 karakter girmeniz gerekmektedir "
                    },
                    {type: ValidationType.MinLength, value: 3, message: "*Minimum 3 karakter girmeniz gerekmektedir "}
                ]
            },
            {
                type: FormItemType.Text,
                name: "fullCompanyName",
                value: "",
                label: "Üye İşyeri/Şirket Tam Adı*",
                rules: [
                    {type: ValidationType.Required},
                    {
                        type: ValidationType.MaxLength,
                        value: 64,
                        message: "*Maksimum 64 karakter girmeniz gerekmektedir "
                    },
                    {type: ValidationType.MinLength, value: 3, message: "*Minimum 3 karakter girmeniz gerekmektedir "}
                ]
            },
            {
                type: FormItemType.Text,
                name: "website",
                value: "",
                label: "İnternet Sitesi",
                // rules: [
                //     { type: ValidationType.Required },
                //     {
                //         type: ValidationType.MaxLength,
                //         value: 64,
                //         message: "*Maksimum 64 karakter girmeniz gerekmektedir "
                //     },
                //     { type: ValidationType.MinLength, value: 3, message: "*Minimum 3 karakter girmeniz gerekmektedir " }
                // ]
            },
            {
                type: FormItemType.Text,
                name: "contactPersonName",
                value: "",
                label: "Yetkili Kişi Adı Soyadı*",
                rules: [
                    {type: ValidationType.Required},
                    {
                        type: ValidationType.MaxLength,
                        value: 64,
                        message: "*Maksimum 64 karakter girmeniz gerekmektedir "
                    },
                    {type: ValidationType.MinLength, value: 3, message: "*Minimum 3 karakter girmeniz gerekmektedir "}
                ]
            },
            {
                type: FormItemType.Text,
                name: "tckn",
                value: "",
                label: "Yetkilinin TC Kimlik Numarası*",
                mask: "99999999999",
                rules: [
                    {type: ValidationType.Required},
                    {
                        type: ValidationType.ExactLength,
                        value: 11,
                        message: "TC Kimlik numarası 11 karakter içermelidir."
                    },
                    {
                        type: ValidationType.TCKN,
                        message: "TC Kimlik numarası 11 karakter içermelidir.",
                        isValid: true
                    }
                ],
                prevention: {type: PreventionType.OnlyNumber}
            },
            {
                type: FormItemType.Text,
                name: "contactPersonEmail",
                value: "",
                label: "Yetkili Kişi E-Postası*",
                rules: [
                    {type: ValidationType.Required},
                    {type: ValidationType.Email, message: "*Lütfen geçerli bir e-posta giriniz."}
                ]
            },
            {
                type: FormItemType.Text,
                name: "contactPersonPhone",
                value: "",
                label: "Yetkili Kişi GSM Numarası*",
                mask: "\\05999999999",

                rules: [
                    {type: ValidationType.Required},

                    {
                        type: ValidationType.GSM, message: "Lütfen geçerli bir GSM numarası giriniz"
                    }


                ]
            },
            {
                type: FormItemType.Text,
                name: "address",
                value: "",
                label: "Yetkili Kişi Adres*",
                rules: [
                    {type: ValidationType.Required},
                    {
                        type: ValidationType.MaxLength,
                        value: 64,
                        message: "*Maksimum 64 karakter girmeniz gerekmektedir "
                    },
                    {type: ValidationType.MinLength, value: 3, message: "*Minimum 3 karakter girmeniz gerekmektedir "}
                ]
            },
            {
                type: FormItemType.Text,
                name: "zipCode",
                value: "",
                label: "Posta Kodu*",
                isValid: true,
                rules: [
                    {type: ValidationType.Required},
                    {
                        type: ValidationType.MaxLength,
                        value: 5,
                        message: "*Maksimum 5 karakter girmeniz gerekmektedir ",
                        isValid: true
                    },
                    {
                        type: ValidationType.MinLength,
                        value: 5,
                        message: "*Minimum 5 karakter girmeniz gerekmektedir ",
                        isValid: true
                    }
                ]
            },
            {
                type: FormItemType.Text,
                name: "city",
                value: "",
                label: "Şehir*",
                isValid: true,
                rules: [
                    {type: ValidationType.Required},
                    {
                        type: ValidationType.MaxLength,
                        value: 64,
                        message: "*Maksimum 64 karakter girmeniz gerekmektedir ",
                        isValid: true
                    },
                    {
                        type: ValidationType.MinLength,
                        value: 3,
                        message: "*Minimum 3 karakter girmeniz gerekmektedir ",
                        isValid: true
                    }
                ]
            },
            {
                type: FormItemType.Text,
                name: "county",
                value: "",
                label: "Ülke*",
                isValid: true,

                rules: [
                    {type: ValidationType.Required},
                    {
                        type: ValidationType.MaxLength,
                        value: 64,
                        message: "*Maksimum 64 karakter girmeniz gerekmektedir ",
                        isValid: true
                    },
                    {
                        type: ValidationType.MinLength,
                        value: 3,
                        message: "*Minimum 3 karakter girmeniz gerekmektedir ",
                        isValid: true
                    }
                ]
            },
            {
                type: FormItemType.Text,
                name: "taxOffice",
                value: "",
                label: "Vergi Dairesi*",
                isValid: true,
                rules: [
                    {type: ValidationType.Required},
                    {
                        type: ValidationType.MaxLength,
                        value: 64,
                        message: "*Maksimum 64 karakter girmeniz gerekmektedir ",
                        isValid: true
                    },
                    {
                        type: ValidationType.MinLength,
                        value: 3,
                        message: "*Minimum 3 karakter girmeniz gerekmektedir ",
                        isValid: true
                    }
                ]
            },
            {
                type: FormItemType.Text,
                name: "taxNumber",
                value: "",
                label: "Vergi Numarası*",
                mask: "9999999999",
                isValid: true,
                rules: [
                    {type: ValidationType.Required},
                    {
                        type: ValidationType.Custom,
                        message: "Vergi numarası rakamlardan oluşmalıdır.",
                        rule: (item: FormItem) => {
                            let result = isNaN(item.value)
                            if (result) return false;
                            return true;
                        }
                    },
                    {
                        type: ValidationType.TaxNo,
                        message: "*Tam olarak 10 rakam girmeniz gerekmektedir",
                        isValid: true
                    },
                ]
            },
            {
                type: FormItemType.Text,
                name: "iban",
                value: "",
                label: "IBAN*",
                mask: "TR999999999999999999999999",
                rules: [
                    {type: ValidationType.Required},
                    {
                        type: ValidationType.IBAN,
                        message: "*Geçerli bir IBAN giriniz",
                        isValid: true
                    }
                ]

            },
            {
                type: FormItemType.Select,
                name: "businessArea",
                value: "",
                label: "Ana Faaliyet Kodu ve Adı*",
                options: convertToNaceCode(),
                isValid: true,
                rules: [
                    {type: ValidationType.Required}
                ]
            },
            {
                type: FormItemType.Text,
                name: "expectedVolume",
                value: "",
                label: "Ortalama Ciro (Aylık)*",
                isValid: true,
                rules: [
                    {type: ValidationType.Required},
                    {
                        type: ValidationType.MaxLength,
                        value: 64,
                        message: "*Maksimum 64 karakter girmeniz gerekmektedir ",
                        isValid: true
                    },
                    {
                        type: ValidationType.MinLength,
                        value: 3,
                        message: "*Minimum 3 karakter girmeniz gerekmektedir ",
                        isValid: true
                    }
                ]
            },
            {
                type: FormItemType.Select,
                name: "preferredPaymentMethod",
                value: "",
                label: "Talep Edilen Ödeme Yöntemleri*",
                options: paymentMethods,
                isValid: true,
                rules: [
                    {type: ValidationType.Required}
                ]
            },
            {
                type: FormItemType.Select,
                name: "partnerExists",
                value: "",
                label: "Şirket Ortaklarınız var mı?*",
                options: companyPartnerQuestions,
                changeFunction: () => {
                    const partnerExists = formModel.items.find(t => t.name == "partnerExists")?.value === 'EVET';
                    setCompanyPartnerStatus(partnerExists);

                    const allCompanyPartners = [...requiredFieldsForCompanyPartners, ...otherFieldsForCompanyPartners];
                    allCompanyPartners.forEach(field => {
                        const target = formModel.items.find(item => item.name === field);
                        if (partnerExists) {
                            target!.isDisabled = false;
                            if (requiredFieldsForCompanyPartners.includes(field)) {
                                target!.isValid = false;//Required fields marked
                            }
                        } else {
                            target!.isDisabled = true;
                            target!.isValid = true;
                            target!.value = '';//Mandatory to delete the value from the parameter
                            (document.getElementsByName(field.toString())[0] as HTMLInputElement).value = '';//Mandatory to delete the value from the input
                        }
                    })
                },
                rules: [
                    {type: ValidationType.Required}
                ]

            },
            {
                type: FormItemType.CustomHtml,
                name: "",
                value: <div className="text s3" style={{marginBottom: "10px"}}>
                    Eğer cevabınız evet ise lütfen aşağıda alanları doldurunuz. </div>
            },
            {
                type: FormItemType.Text,
                name: "partner1Name",
                value: "",
                label: "1. Ortak Adı Soyadı*",
                isDisabled: true,
                isValid: true,
                rules: [
                    {companyPartnerStatus} && {
                        type: ValidationType.MinLength,
                        value: 5,
                        message: "*Minimum 5 karakter girmeniz gerekmektedir ",
                        isValid: true
                    }
                ]
            },
            {
                type: FormItemType.Text,
                name: "partner1Share",
                value: "",
                label: "1. Ortak Hisse Oranı*",
                isDisabled: true,
                isValid: true,
                rules: [
                    {companyPartnerStatus} && {
                        type: ValidationType.MaxLength,
                        value: 2,
                        message: "*Maksimum 2 karakter girmeniz gerekmektedir ",
                        isValid: true
                    },
                    {companyPartnerStatus} && {
                        type: ValidationType.MinLength,
                        value: 1,
                        message: "*Minimum 1 karakter girmeniz gerekmektedir ",
                        isValid: true
                    },
                    {companyPartnerStatus} && {
                        type: ValidationType.Custom,
                        message: "1. ortak hisse oranı rakamlardan oluşmalıdır.",
                        rule: (item: FormItem) => {
                            let result = isNaN(item.value)
                            if (result) return false;
                            return true;
                        }
                    },
                    {companyPartnerStatus} && {
                        type: ValidationType.Custom,
                        message: "Girilen değer 0'dan büyük olmalıdır.",
                        rule: (item: FormItem) => {
                            let result = parseInt(item.value) == 0;
                            if (result) return false;
                            return true;
                        }
                    },
                ]
            },
            {
                type: FormItemType.Text,
                name: "partner2Name",
                value: "",
                label: "2. Ortak Adı Soyadı*",
                isDisabled: true,
                isValid: true,
                rules: [
                    {companyPartnerStatus} && {
                        type: ValidationType.MinLength,
                        value: 5,
                        message: "*Minimum 5 karakter girmeniz gerekmektedir ",
                        isValid: true
                    }
                ]
            },
            {
                type: FormItemType.Text,
                name: "partner2Share",
                value: "",
                label: "2. Ortak Hisse Oranı*",
                isDisabled: true,
                isValid: true,
                rules: [
                    {companyPartnerStatus} && {
                        type: ValidationType.MaxLength,
                        value: 2,
                        message: "*Maksimum 2 karakter girmeniz gerekmektedir ",
                        isValid: true
                    },
                    {companyPartnerStatus} && {
                        type: ValidationType.MinLength,
                        value: 1,
                        message: "*Minimum 1 karakter girmeniz gerekmektedir ",
                        isValid: true
                    },
                    {companyPartnerStatus} && {
                        type: ValidationType.Custom,
                        message: "2. ortak hisse oranı rakamlardan oluşmalıdır.",
                        rule: (item: FormItem) => {
                            let result = isNaN(item.value)
                            if (result) return false;
                            return true;
                        }
                    },
                    {companyPartnerStatus} && {
                        type: ValidationType.Custom,
                        message: "Girilen değer 0'dan büyük olmalıdır.",
                        rule: (item: FormItem) => {
                            let result = parseInt(item.value) == 0;
                            if (result) return false;
                            return true;
                        }
                    },
                ]
            },
            {
                type: FormItemType.Text,
                name: "partner3Name",
                value: "",
                label: "3. Ortak Adı Soyadı",
                isDisabled: true,
                isValid: true,
            },
            {
                type: FormItemType.Text,
                name: "partner3Share",
                value: "",
                label: "3. Ortak Hisse Oranı",
                isDisabled: true,
                isValid: true,
            },
            {
                type: FormItemType.Text,
                name: "partner4Name",
                value: "",
                label: "4. Ortak Adı Soyadı",
                isDisabled: true,
                isValid: true,
            },
            {
                type: FormItemType.Text,
                name: "partner4Share",
                value: "",
                label: "4. Ortak Hisse Oranı",
                isDisabled: true,
                isValid: true,
            },
            {
                type: FormItemType.Text,
                name: "partner5Name",
                value: "",
                label: "5. Ortak Adı Soyadı",
                isDisabled: true,
                isValid: true,
            },
            {
                type: FormItemType.Text,
                name: "partner5Share",
                value: "",
                label: "5. Ortak Hisse Oranı",
                isDisabled: true,
                isValid: true,
            },
            {
                type: FormItemType.CustomHtml,
                name: "",
                isValid: true,
                value: <div className="text s3" style={{marginBottom: "10px"}}>
                    Değerli Üye İşyeri Yetkilisi, Linkte yer alan <a href={"/Üye İş Yeri Bilgi Formu.docx"}
                                                                     style={{color: "#4285f4"}} target="_blank"
                                                                     rel="noreferrer">“DETAYLI BAŞVURU FORMU”</a>
                    çalışma koşullarınızı belirlemeniz içindir. Lütfen seçenekleri el yazınızla eksiksiz doldurunuz ve
                    imzalayınız.
                    Dosya yükle alanından Şirketinize ait Vergi Levhası, Oda Kaydı, Ticaret Sicil Gazetesi, İmza
                    Beyannameniz ve/ veya İmza Sirkülerinizle birlikte tarafımızla paylaşınız.
                </div>,
            },
            {
                type: FormItemType.File,
                name: "signUpFiles",
                label: "",
                value: "",
                rules: [
                    {type: ValidationType.Required},
                    {
                        type: ValidationType.Custom,
                        text: "Yüklediğiniz dosya boyutu maksimum 25 MB olmalıdır.",
                        rule: () => {
                            const fileSize = calculateSignUpFilesTotalSize(formModel);

                            if (fileSize > 26214400) {
                                return false;
                            }
                            return true;
                        }
                    },
                    {
                        type: ValidationType.Custom,
                        text: "Yüklediğiniz dosya boyutu minimum 1 KB olmalıdır.",
                        rule: () => {
                            const fileSize = calculateSignUpFilesTotalSize(formModel);

                            if (fileSize < 1000) {
                                return false;
                            }
                            return true;
                        }

                    },
                ]
            },
            {
                type: FormItemType.ReCaptcha,
                name: "re-captcha",
                value: "",
                rules: [
                    //       { type: ValidationType.Required },
                    //     { type: ValidationType.ReCaptcha }
                ]
            },
            {
                type: FormItemType.Button,
                name: "submit",
                label: "Üye İşyeri Ol",
                classNames: "large",
                value: "",
                isValid: false,
                rules: [{type: ValidationType.AllValidated}]
            },
            {
                type: FormItemType.Checkbox,
                name: "userConsent",
                value: "",
                isDisabled: false,
                changeFunction: (item: FormItem) => {
                    item.isDisabled = true
                    window.open("/aydinlatma-metni.pdf");
                },
                label: "Kişisel verileriniz, talebinizin gerçekleştirilmesi amacıyla 6698 Sayılı Kanun kapsamında işlenmektedir. KVKK Aydınlatma Metni'ni okudum ve kabul ediyorum.",
                classNames: "full",
                rules: [
                    {type: ValidationType.Required, message: "Üyeliğinizi tamamlayabilmek için kutucuğu işaretleyin"},
                ]
            },
            {
                type: FormItemType.CustomHtml,
                name: "",
                isValid: true,
                value: <div className="text s2">
                    KVKK Aydınlatma metnine <a href="/aydinlatma-metni.pdf" title="KVKK Aydınlatma metni"
                                               target="_blank"><b>buradan</b></a> ulaşabilirsiniz.
                </div>,
            },
        ]
    });

    const [registrationCompleted, setRegistrationCompleted] = useState<boolean>(false);

    const formSubmitFunction = (model: any) => {

        let formData = new FormData();
        let signUpFiles = model?.signUpFiles;

        new URLSearchParams(model).forEach(function (value, key) {
            formData.append(key, value);
        });

        if (signUpFiles != null) {
            for (let i = 0; i < signUpFiles!.length; i++) {
                if (signUpFiles && signUpFiles.length > 0) {
                    formData.append("files", signUpFiles[i]);
                }
            }

            register(formData, (response: any) => {
                setRegistrationCompleted(true);
            });
        }
    }

    const [formSmsModel] = useState<FormModel>({
        items: [
            {
                type: FormItemType.SplitInput,
                name: "otp",
                value: "",
                label: "6 haneli doğrulama kodunu girin",
                customValue: {charCount: 6, cursorPosition: 0},
                rules: [{type: ValidationType.Required}, {type: ValidationType.ExactLength, value: 6}],
                prevention: {type: PreventionType.OnlyNumber},
                changeFunction: (item: FormItem) => {
                    if (item.value.length === item.customValue.charCount) {
                        setSecondStepModel(item.value);
                    }
                }
            },
            {
                type: FormItemType.CustomHtml,
                name: "",
                value: <><b>{firstStepModel?.mobileNumber}</b> numaralı telefona gönderilen 6 haneli kodu girin.</>,
                classNames: "sms-desc"
            }
        ]
    });

    const showPopup = () => {
        if (isPopupShowed) {
            var message = {
                classNames: "result",
                title: "5549 Sayılı Suç Gelirlerinin Aklanmasının Önlenmesi Hakkında Kanunun Kapsamında Önemli Bilgilendirme",
                text: "5549 sayılı Kanun’un 15. maddesinde “Yükümlüler nezdinde veya aracılığıyla yapılacak kimlik tespitini gerektiren işlemlerde, kendi adına ve fakat başkası hesabına hareket eden kimse, bu işlemleri yapmadan önce kimin hesabına hareket ettiğini yükümlülere yazılı olarak bildirmediği takdirde altı aydan bir yıla kadar hapis veya beş bin güne kadar adli para cezasıyla cezalandırılır.” hükmü bulunmaktadır.  Bu düzenleme çerçevesinde, herhangi bir yasal yaptırıma maruz kalmamanız için kimlik tespitini gerektiren işlemleri, kendi adınıza ve fakat başkası hesabına hareket ederek gerçekleştirecek iseniz bu durumu yazılı olarak beyan etmenizi rica ederiz. Bu kapsamda dolduracağınız beyanda Şirketi / Üye İş Yerini Temsilen işlem yapıyorsanız lütfen *Üye İş yeri Adına İşlem Yapan Temsile Yetkili Kişi Adı Soyadı alanını doldurunuz.",
                btnPrimaryText: "Kapat",
                btnPrimaryCallback: () => {
                    dispatch(hideMessage());
                },
                image: require("../../../assets/Icons/warning.svg").default,
                isMobileFull: true
            };
            dispatch(showMessage(message));
            setPopupShowed(false)
            return;
        }
    }

    const calculateSignUpFilesTotalSize = (formModel: { items: any }) => {
        let signUpFilesItem = formModel.items?.find((item: { name: string; }) => item?.name == "signUpFiles");

        let fileSize = 0;
        const signUpFiles = signUpFilesItem?.value;

        if (signUpFiles != null) {
            for (let i = 0; i < signUpFiles!.length; i++) {
                if (signUpFiles && signUpFiles.length > 0) {
                    fileSize += signUpFiles[i].size;
                }
            }
        }
        return fileSize;
    }

    showPopup();
    return (
        <section className="auth register">
            <div className="container small">
                {!registrationCompleted &&
                    <>
                        {firstStepModel === null ?
                            <>
                                <div className="nav mobile-only">
                                    <Button classNames="tertiary no-border" link="/dashboard" text="Giriş Yap"/>
                                    <Button classNames="tertiary no-border active" link="/signup" text="Kayıt Ol"/>
                                </div>
                                <div className="form">
                                    <Title classNames="desktop-only" size={4}>
                                        Üye İşyeri Ol
                                    </Title>
                                    <Form key={"register-form"} formModel={formModel}
                                          submitFunction={formSubmitFunction}/>
                                </div>
                            </>
                            :
                            <Sms title="SMS Onay" model={formSmsModel} countdownMiliSeconds={180000}
                                 btnReSendActivateTime={60000}/>
                        }
                    </>
                }
                {registrationCompleted &&
                    <div className="success">
                        <Image src={require('../../../assets/Icons/user-large.svg').default}></Image>
                        <Title size={4}>
                            Üyelik Talebinizi Aldık
                        </Title>
                        <Text size={2}>
                            Değerli Üye İşyerimiz, Başvurunuz alınmıştır, en kısa sürede tarafınıza dönüş yapılacaktır.
                            Bizi tercih ettiğiniz için teşekkür ederiz.
                        </Text>
                        <Button link="/" text="Tamam"></Button>
                    </div>
                }
            </div>


        </section>
    )
}

export default SignUpPage;