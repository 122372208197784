import {Validation} from "../models/types";
import Image from '../../modules/image';
import Text from '../../modules/text';

type IndividualMessageProps = {
    rules?: Array<Validation>
}

const IndividualMessage = (props: IndividualMessageProps) => {
    const individualMessage = props.rules?.filter(x => x.text);

    if (individualMessage) {
        return (
            <div className="validations">
                {individualMessage.map((item, index) => (
                    <div key={"validation-item-" + index}
                         className={"validation" + (item.isValid ? " valid" : item.isValid === false ? " invalid" : "")}>
                        {item.isValid === true &&
                            <Image src={require('../../../assets/Icons/valid.svg').default}></Image>
                        }
                        {item.isValid === false &&
                            <Image src={require('../../../assets/Icons/invalid.svg').default}></Image>
                        }
                        {item.isValid === undefined &&
                            <Image src={require('../../../assets/Icons/rule.svg').default}></Image>
                        }
                        <Text size={4}>
                            {item.text}
                        </Text>
                    </div>
                ))}
            </div>
        )
    }

    return (null);
}

export default IndividualMessage;