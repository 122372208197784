import MainContent from '../../components/common/main-content';
import MainBoxes from '../../components/common/main-boxes';
import CookieCommonConsent from "../../components/common/cookie-consent";

const HomePage = () => {
    return (
        <>
            <MainContent
                preTitle="İşletmem İçin"
                image={require('../../assets/Uploads/corp.png')}
                title="YemeksepetiPay ile Online Ödemelerinizi Hızlı ve Güvenli Bir Şekilde Alın!"
                text="YemeksepetiPay Sanal POS’u ile online sipariş ödemelerinizi hızlı, güvenli ve avantajlı komisyonlarla alabileceksiniz!"
                dotsDireciton/>

            <MainBoxes
                preTitle="Özelliklerimiz"
                title="Neden YemeksepetiPay Sanal POS’u?"
                items={[{
                    title: "Ödemelerinizi Ertesi Gün Alma İmkanı",
                    text: "Ertesi gün vade süresiyle çalışma opsiyonumuz ile ödemelerinizi ertesi gün alabileceksiniz.",
                    image: require('../../assets/Uploads/rocket.svg').default
                },
                    {
                        title: "Üye İş Yeri Paneli ve Raporlama Özelliği",
                        text: "Üye iş yeri paneli üzerinden bakiyenizi ve işlem detaylarınızı kolayca takip edebileceksiniz.",
                        image: require('../../assets/Uploads/report.svg').default
                    },
                    {
                        title: "Güvenli Ödeme Hizmeti",
                        text: "Tecrübeli ekibimiz ve güçlü altyapımız ile tahsilat işlemlerinizde daima güvende olduğunuzu hissedeceksiniz.",
                        image: require('../../assets/Uploads/secure.svg').default
                    }]}/>
        </>
    )
}

export default HomePage;