import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import './register.scss';
import Form from '../../../components/form';
import {
    FormItem,
    FormItemOption,
    FormItemType,
    FormModel,
    PreventionType,
    ValidationType
} from '../../../components/form/models/types';
import Title from '../../../components/modules/title';
import Text from '../../../components/modules/text';
import Image from '../../../components/modules/image';
import Button from '../../../components/modules/button';
import Sms from '../../../components/common/smsconfirm';
import {register, registerValidate} from '../../../actions/authenticationActions';
import {getSecurityQuestions} from '../../../actions/globalActions';
import CookieCommonConsent from "../../../components/common/cookie-consent";

const RegisterPage = () => {
    const [firstStepModel, setFirstStepModel] = useState<any>(null);
    const [secondStepModel, setSecondStepModel] = useState<any>(null);
    const [formModel, setFormModel] = useState<FormModel>({
        items: [
            {
                type: FormItemType.Text,
                name: "firstName",
                value: "",
                label: "Yetkilinin Adı*",
                rules: [
                    {type: ValidationType.Required},
                    {
                        type: ValidationType.MaxLength,
                        value: 64,
                        message: "*Maksimum 64 karakter girmeniz gerekmektedir "
                    },
                    {type: ValidationType.MinLength, value: 3, message: "*Minimum 3 karakter girmeniz gerekmektedir "}
                ]
            },
            {
                type: FormItemType.Text,
                name: "lastName",
                value: "",
                label: "Yetkilinin Soyadı*",
                rules: [
                    {type: ValidationType.Required},
                    {
                        type: ValidationType.MaxLength,
                        value: 64,
                        message: "*Maksimum 64 karakter girmeniz gerekmektedir "
                    },
                    {type: ValidationType.MinLength, value: 3, message: "*Minimum 3 karakter girmeniz gerekmektedir "}
                ]
            },
            {
                type: FormItemType.Text,
                name: "mobileNumber",
                value: "",
                label: "Yetkilinin Cep Telefonu*",
                mask: "+\\905999999999",
                rules: [
                    {type: ValidationType.Required},
                    {
                        type: ValidationType.Custom, rule: (item: FormItem) => {
                            return item.value.replace(/_/g, "").length === 13;
                        }
                    }
                ]
            },
            {
                type: FormItemType.Email,
                name: "email",
                value: "",
                label: "Yetkilinin E-postası*",
                rules: [
                    {type: ValidationType.Required},
                    {type: ValidationType.Email, message: "*Lütfen geçerli bir e-posta giriniz."}
                ]
            },
            {
                type: FormItemType.Select,
                name: "securityQuestion",
                value: "",
                label: "Güvenlik Sorusu Seçiniz*",
                rules: [
                    {type: ValidationType.Required}
                ]
            },
            {
                type: FormItemType.Text,
                name: "securityQuestionAnswer",
                value: "",
                label: "Güvenlik Sorusu Cevabı*",
                rules: [
                    {type: ValidationType.Required},
                    {
                        type: ValidationType.MaxLength,
                        value: 64,
                        message: "*Maksimum 64 karakter girmeniz gerekmektedir "
                    },
                    {type: ValidationType.MinLength, value: 3, message: "*Minimum 3 karakter girmeniz gerekmektedir "}
                ]
            },
            {
                type: FormItemType.Password,
                name: "password",
                value: "",
                label: "Şifre*",
                rules: [
                    {type: ValidationType.Required},
                    {type: ValidationType.ExactLength, value: 6, text: "6 adet rakamdan oluşmalı"},
                    {
                        type: ValidationType.Custom, text: "En az 3 farklı rakam içermeli", rule: (item: FormItem) => {
                            let count = 0;
                            (item.value + "").split('').forEach((char) => {
                                const l = item.value.match(new RegExp(char + "", "g")).length;
                                count += l > 1 ? 1 / l : 1;
                            });

                            return count >= 3;
                        }
                    },
                    {
                        type: ValidationType.Custom,
                        text: "En fazla 2 ardışık rakam yan yana bulunmalı",
                        rule: (item: FormItem) => {
                            if (item.value.length === 6) {
                                for (let i = 0; i < item.value.length - 2; i++) {
                                    if ("0123456789".indexOf(item.value.substr(i, 3)) !== -1) {
                                        return false;
                                    }
                                }

                                return true;
                            }

                            return false;
                        }
                    },
                    {
                        type: ValidationType.Custom,
                        text: "Aynı rakam arka arkaya en fazla 2 kez kullanılmalı",
                        rule: (item: FormItem) => {
                            return !(/(.)\1\1/.test(item.value));
                        }
                    },
                    {
                        type: ValidationType.Custom,
                        text: "Ardışık en fazla 2 rakam kullanılmalı",
                        rule: (item: FormItem) => {
                            if (item.value.length === 6) {
                                let count = 0;
                                for (let i = 0; i < item.value.length - 2; i++) {
                                    if ("0123456789".indexOf(item.value.substr(i, 2)) !== -1) {
                                        count++;
                                    }
                                }

                                return count < 2;
                            }

                            return false;
                        }
                    },
                    {
                        type: ValidationType.Custom,
                        text: "İlk 3 rakam ile son 3 rakam farklı olmalı",
                        rule: (item: FormItem) => {
                            return item.value.length === 6 && item.value.substr(3) !== item.value.substring(0, 3);
                        }
                    }
                ],
                prevention: {
                    type: PreventionType.Custom, rule: (event: React.KeyboardEvent) => {
                        if ("0123456789".indexOf(event.key) === -1 || (event.currentTarget as HTMLInputElement).value.length >= 6) {
                            event.preventDefault();
                            return false;
                        }

                        return true;
                    }
                }
            },
            {
                type: FormItemType.Password,
                name: "passwordConfirmation",
                value: "",
                label: "Şifre Tekrar*",
                rules: [
                    {type: ValidationType.Required},
                    {
                        type: ValidationType.SameWith,
                        value: "password",
                        text: "Girilen şifreler birbiri ile aynı olmalı"
                    },
                ],
                prevention: {
                    type: PreventionType.Custom, rule: (event: React.KeyboardEvent) => {
                        if ("0123456789".indexOf(event.key) === -1 || (event.currentTarget as HTMLInputElement).value.length >= 6) {
                            event.preventDefault();
                            return false;
                        }

                        return true;
                    }
                }
            },
            {
                type: FormItemType.Button,
                name: "submit",
                label: "Kayıt Ol",
                classNames: "large",
                value: "",
                rules: [{type: ValidationType.AllValidated}]
            }
        ]
    });

    const formSubmitFunction = (model: any) => {
        registerValidate(model, (response: any) => {
            setFirstStepModel(model);
        });
    }

    const [formSmsModel] = useState<FormModel>({
        items: [
            {
                type: FormItemType.SplitInput,
                name: "otp",
                value: "",
                label: "6 haneli doğrulama kodunu girin",
                customValue: {charCount: 6, cursorPosition: 0},
                rules: [{type: ValidationType.Required}, {type: ValidationType.ExactLength, value: 6}],
                prevention: {type: PreventionType.OnlyNumber},
                changeFunction: (item: FormItem) => {
                    if (item.value.length === item.customValue.charCount) {
                        setSecondStepModel(item.value);
                    }
                }
            },
            {
                type: FormItemType.CustomHtml,
                name: "",
                value: <><b>{firstStepModel?.mobileNumber}</b> numaralı telefona gönderilen 6 haneli kodu girin.</>,
                classNames: "sms-desc"
            }
        ]
    });

    const [registrationCompleted, setRegistrationCompleted] = useState<boolean>(false);

    const onSmsBackClick = () => {
        setFirstStepModel(null);
        setSecondStepModel(null);
    }

    const onSmsReSendClick = () => {
        registerValidate(firstStepModel, (response: any) => {
            setFirstStepModel(firstStepModel);
        });
    }

    useEffect(() => {
        getSecurityQuestions((options: Array<FormItemOption>) => {
            var securityQuestions = formModel.items.find(x => x.name === "securityQuestion");

            if (securityQuestions) {
                securityQuestions.options = options;

                setFormModel(formModel);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        console.log(firstStepModel);
        if (firstStepModel && secondStepModel) {
            let model = firstStepModel;
            model.otp = secondStepModel;

            register(model, (response: any) => {
                setRegistrationCompleted(true);
            });
        }
    }, [firstStepModel, secondStepModel])

    return (
        <section className="auth register">
            <div className="container small">
                {!registrationCompleted &&
                    <>
                        {firstStepModel === null ?
                            <>
                                <div className="nav mobile-only">
                                    <Button classNames="tertiary no-border" link="/dashboard" text="Giriş Yap"/>
                                    <Button classNames="tertiary no-border active" link="/signup" text="Kayıt Ol"/>
                                </div>
                                <div className="form">
                                    <Title classNames="desktop-only" size={4}>
                                        Kayıt Ol
                                    </Title>
                                    <Form key={"register-form"} formModel={formModel}
                                          submitFunction={formSubmitFunction}/>
                                    <div className="text s3">
                                        Kişisel verileriniz, talebinizin gerçekleştirilmesi amacıyla 6698 Sayılı Kanun
                                        kapsamında işlenmektedir. Kişisel verileriniz ile ilgili Aydınlatma Metnine <a
                                        href="/" title="KVKK Aydınlatma metni"
                                        target="_blank"><b>buradan</b></a> ulaşabilirsiniz.
                                    </div>
                                </div>
                                <div className="text s2 bottom-text">
                                    Üyeliğin var mı? <Link className="title s6" to="/dashboard" title="Giriş Yap">Giriş
                                    Yap</Link>
                                </div>
                            </>
                            :
                            <Sms title="SMS Onay" model={formSmsModel} countdownMiliSeconds={180000}
                                 btnReSendActivateTime={60000} btnReSendCallback={onSmsReSendClick}
                                 btnBackCallback={onSmsBackClick}/>
                        }
                    </>
                }
                {registrationCompleted &&
                    <div className="success">
                        <Image src={require('../../../assets/Icons/user-large.svg').default}></Image>
                        <Title size={4}>
                            Üyelik Talebinizi Aldık
                        </Title>
                        <Text size={2}>
                            Değerli Üye İşyerimiz, Başvurunuz alınmıştır, en kısa sürede tarafınıza dönüş yapılacaktır.
                            Bizi tercih ettiğiniz için teşekkür ederiz.
                        </Text>
                        <Button link="/dashboard" text="Tamam"></Button>
                    </div>
                }
            </div>


        </section>
    )
}

export default RegisterPage;