import {FormItemOption} from '../components/form/models/types';
import {AxiosModel, call} from './apiCaller';

export const contactNewUser = (data: any, successFunc?: Function, errorFunc?: Function) => {
    let model: AxiosModel = {
        url: 'merchant/customer-support',
        method: 'post',
        data: data,
        successFunc: successFunc,
        errorFunc: errorFunc
    }

    call(model);
}

export const getNationalities = (successFunc?: Function, errorFunc?: Function) => {
    let onSuccess = (response: any) => {
        let options: Array<FormItemOption> = [];

        for (let i = 0; i < response.payload.length; i++) {
            options.push({label: response.payload[i].name, value: response.payload[i].code});
        }

        if (successFunc) {
            successFunc(options);
        }
    }

    let model: AxiosModel = {
        url: 'merchant/nationalities',
        method: 'get',
        successFunc: onSuccess,
        errorFunc: errorFunc
    }

    call(model);
}

export const getSecurityQuestions = (successFunc?: Function, errorFunc?: Function) => {
    let onSuccess = (response: any) => {
        let options: Array<FormItemOption> = [];

        for (let i = 0; i < response.payload.length; i++) {
            options.push({label: response.payload[i].question, value: response.payload[i].id});
        }

        if (successFunc) {
            successFunc(options);
        }
    }

    let model: AxiosModel = {
        url: 'merchant/security-questions',
        method: 'get',
        successFunc: onSuccess,
        errorFunc: errorFunc,
        preventMessage: true
    }

    call(model);
}

export const getCities = (successFunc?: Function, errorFunc?: Function) => {
    let onSuccess = (response: any) => {
        let options: Array<FormItemOption> = [];

        for (let i = 0; i < response.payload.length; i++) {
            options.push({label: response.payload[i].name, value: response.payload[i].code});
        }

        if (successFunc) {
            successFunc(options);
        }
    }

    let model: AxiosModel = {
        url: 'merchant/cities',
        method: 'get',
        successFunc: onSuccess,
        errorFunc: errorFunc
    }

    call(model);
}

export const getCounties = (cityCode: string, successFunc?: Function, errorFunc?: Function) => {
    let onSuccess = (response: any) => {
        let options: Array<FormItemOption> = [];

        for (let i = 0; i < response.payload.length; i++) {
            options.push({label: response.payload[i].name, value: response.payload[i].code});
        }

        if (successFunc) {
            successFunc(options);
        }
    }

    let model: AxiosModel = {
        url: 'merchant/counties',
        method: 'get',
        params: {city: cityCode},
        successFunc: onSuccess,
        errorFunc: errorFunc
    }

    call(model);
}

export const getDistricts = (countyCode: string, successFunc?: Function, errorFunc?: Function) => {
    let onSuccess = (response: any) => {
        let options: Array<FormItemOption> = [];

        for (let i = 0; i < response.payload.length; i++) {
            options.push({label: response.payload[i].name, value: response.payload[i].code});
        }

        if (successFunc) {
            successFunc(options);
        }
    }

    let model: AxiosModel = {
        url: 'merchant/districts',
        method: 'get',
        params: {county: countyCode},
        successFunc: onSuccess,
        errorFunc: errorFunc
    }

    call(model);
}