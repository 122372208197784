import {AxiosModel, call} from './apiCaller';

export const registerValidate = (data: any, successFunc?: Function, errorFunc?: Function) => {
    let model: AxiosModel = {
        url: 'merchant/sign-up-validate',
        method: 'post',
        data: data,
        successFunc: successFunc,
        errorFunc: errorFunc
    }

    call(model);
}

export const register = (data: any, successFunc?: Function, errorFunc?: Function) => {
    let model: AxiosModel = {
        url: 'merchant/registration',
        method: 'post',
        data: data,
        successFunc: successFunc,
        errorFunc: errorFunc
    }

    call(model);
}

export const logout = (successFunc?: Function, errorFunc?: Function) => {
    let model: AxiosModel = {
        url: 'logout',
        method: 'post',
        errorFunc: errorFunc,
        apiPath: 'oauth2'
    }

    model.successFunc = (response: any) => {
        //store.dispatch(setUserInfo(undefined));
        //store.dispatch(setCompanyInfo(undefined));

        if (successFunc) {
            successFunc(response);
        }
    }

    call(model);
}