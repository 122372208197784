import {FormItem} from "../models/types";
import ErrorMessage from './errorMessage';
import IndividualMessage from './individualMessage';
import DatePicker from "react-datepicker";
import tr from "date-fns/locale/tr";
import "react-datepicker/dist/react-datepicker.css";
import {useState} from "react";

type InputDateProps = {
    item: FormItem,
    changeFunction: Function
}

const InputDate = (props: InputDateProps) => {
    const currentDate = new Date();
    const [maxDate, setMaxDate] = useState<Date>(currentDate);

    let disabled: any = {};

    if (props.item.isDisabled) {
        disabled["disabled"] = "disabled";
    }

    const onChange = (date: [Date]) => {
        props.changeFunction(date, props.item.name);

        let startDate = new Date(date[0]);

        if ((currentDate.getTime() - date[0].getTime()) / (1000 * 60 * 60 * 24) > 31) {
            startDate.setDate(startDate.getDate() + 30);
            setMaxDate(startDate);
        } else {
            setMaxDate(currentDate);
        }
    }

    return (
        <div
            className={"form-item" + (props.item.isValid === false ? " has-error" : "") + (props.item.classNames ? " " + props.item.classNames : "")}>
            <DatePicker
                className="button s1"
                name={props.item.name}
                selectsRange={true}
                startDate={props.item.value[0]}
                endDate={props.item.value[1]}
                onChange={onChange}
                autoComplete="off"
                dateFormat="d MMMM yyyy"
                locale={tr}
                maxDate={maxDate}
                minDate={props.item.customProps ? props.item.customProps.minDate : undefined}
                {...disabled}
                disabledKeyboardNavigation
            />
            <ErrorMessage rules={props.item.rules}/>
            <IndividualMessage rules={props.item.rules}/>
        </div>
    )
}

export default InputDate;