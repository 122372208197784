import {useCallback, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import Form from "../../../../components/form";
import {FormItem, FormItemType, FormModel, ValidationType} from "../../../../components/form/models/types";
import Image from "../../../../components/modules/image";
import Title from "../../../../components/modules/title";
import Text from "../../../../components/modules/text";
import './payout.scss';
import {getTransactions, payoutRequest} from "../../../../actions/dashboardActions";
import {format} from "date-fns";
import {useAppSelector} from "../../../../store/hooks";
import {getCompanyInfo} from "../../../../store/globalSlice";

const DashboardPayout = () => {
    let now = new Date();
    let price = "";
    const companyInfo = useAppSelector(getCompanyInfo);

    const [balance, setBalance] = useState<string>("0");
    const [amount, setAmount] = useState<string>("");
    const [formModel, setFormModel] = useState<FormModel>({
        items: [
            {
                type: FormItemType.Number,
                name: "amount",
                value: balance,
                label: "Tutar*",
                classNames: "currency",
                isDisabled: true,
                rules: [
                    {type: ValidationType.Required}
                ],
                changeFunction: (item: FormItem) => {
                    price = item.value;
                }
            },
            {
                type: FormItemType.Button,
                name: "submit",
                label: "Devam Et",
                classNames: "large",
                value: ""
            }
        ]
    });

    const formSubmitFunction = useCallback((model: any) => {
        setAmount(price);
    }, [price])

    let btnCloseClick = (event: React.MouseEvent) => {
        setAmount("");
    }

    let btnConfirmClick = (event: React.MouseEvent) => {
        setAmount("");

        payoutRequest({
            amount: amount.replace(",", ".").replace("TL", ""),
            restaurantId: companyInfo?.sapId
        });
    }

    useEffect(() => {
        getTransactions({
            restaurantId: companyInfo?.sapId,
            recordsPerPage: 1000,
            pageNumber: 1,
            showActivities: true,
            documentDateStart: format(now.setDate(now.getDate() - 30), "yyyy-MM-dd"),
            documentDateEnd: format(new Date(), "yyyy-MM-dd")
        }, (arr: Array<Array<string>>, balance: string) => {
            let input = formModel.items.find(x => x.name == "amount");

            if (input) {
                input.value = balance;
            }

            setBalance(balance);
            setFormModel({...formModel});
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="payout">
            <Link className="btn-back" to="/dashboard/financial" title="Finansal İşlemlere Dön">
                <Image src={require("../../../../assets/Icons/arrow-left-small.svg").default}/>
                <span className="button s1">Finansal İşlemlere Dön</span>
            </Link>
            <Title size={4}>
                Para Çekme Talebi
            </Title>
            <div className="box-container">
                <div className="box">
                    <div className="info">
                        <Title size={7}>
                            Güncel Bakiyem
                        </Title>
                        <Title classNames="price" size={4}>
                            {balance + " TL"}
                        </Title>
                    </div>
                    <div className="form">
                        <Title size={7}>
                            Tutar Girişi Yapın
                        </Title>
                        <Form key={"payout-form"} formModel={formModel} submitFunction={formSubmitFunction}/>
                    </div>
                </div>
            </div>
            {amount.length > 0 &&
                <div className="popup-wrapper">
                    <div className="popup-container">
                        <Title size={4}>
                            {amount + " Tutarındaki Para Çekme Talebinizi onaylıyor musunuz?"}
                        </Title>
                        <div className="payout-table">
                            <div className="table-header font">
                                Ücret Dağılımları
                            </div>
                            <table>
                                <thead>
                                <tr>
                                    <th className="title s6">Ödeme Zamanı</th>
                                    <th className="title s6">Komisyon Oranı</th>
                                    <th className="title s6">Sabit Değer</th>
                                </tr>
                                </thead>
                                <tbody>
                                {/* <tr>
                                        <td className="text s5">1-3 Gün İçerisinde</td>
                                        <td className="text s5">%3</td>
                                        <td className="text s5">2.5 TL</td>
                                    </tr>
                                    <tr>
                                        <td className="text s5">3-5 Gün İçerisinde</td>
                                        <td className="text s5">%4</td>
                                        <td className="text s5">2 TL</td>
                                    </tr>
                                    <tr>
                                        <td className="text s5">5-6 Gün İçerisinde</td>
                                        <td className="text s5">%1</td>
                                        <td className="text s5">1.5 TL</td>
                                    </tr> */}
                                <tr>
                                    <td className="text s5">7 Gün (Vade Gününde)</td>
                                    <td className="text s5">%2</td>
                                    <td className="text s5">1TL</td>
                                </tr>
                                </tbody>
                            </table>
                            <Text size={2}>
                                %0.5 BSMV ücreti toplam miktarınızdan kesilir. Örneğin; 4 günlük vadesi olan 1000 TL
                                tutarındaki para çekme işleminizde %2 komisyon ve 2 TL sabit ücret ile
                                ücretlendirilirsiniz.
                            </Text>
                        </div>
                        <div className="payout-description">
                            <Title size={6}>
                                Örnek Hesaplama
                            </Title>
                            <div className="desc-item">
                                <Text size={3}>
                                    Komisyon ücreti:
                                </Text>
                                <Text size={5}>
                                    %2*1000 = 20 TL
                                </Text>
                            </div>
                            <div className="desc-item">
                                <Text size={3}>
                                    Sabit Ücret:
                                </Text>
                                <Text size={5}>
                                    2 TL
                                </Text>
                            </div>
                            <div className="desc-item">
                                <Text size={5}>
                                    %0.5*1000 = 5 TL
                                </Text>
                                <Text size={3}>
                                    BSMV (Vergiler Dahil):
                                </Text>
                            </div>
                            <div className="desc-item">
                                <Text size={3}>
                                    Toplam Kesinti:
                                </Text>
                                <Text size={5}>
                                    %2*1000 = 20 TL
                                </Text>
                            </div>
                            <div className="desc-item">
                                <Text size={3}>
                                    Hesabınıza Geçecek Tutar:
                                </Text>
                                <Text size={5}>
                                    973 TL
                                </Text>
                            </div>
                            <div className="desc-item"></div>
                        </div>
                        <div className="buttons">
                            <button className="btn large cancel" type="button" title="Vazgeç"
                                    onClick={(event) => btnCloseClick(event)}>Vazgeç
                            </button>
                            <button className="btn large" type="button" title="Onayla"
                                    onClick={(event) => btnConfirmClick(event)}>Onayla
                            </button>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default DashboardPayout;