import {format} from 'date-fns';
import {useEffect, useState} from 'react';
import {getTransactions} from '../../../actions/dashboardActions';
import {FormItem, FormItemType, FormModel} from '../../../components/form/models/types';
import Table from '../../../components/modules/table';
import {getCompanyInfo} from '../../../store/globalSlice';
import {useAppSelector} from '../../../store/hooks';

const DashboardTransactions = () => {
    let now = new Date();
    const companyInfo = useAppSelector(getCompanyInfo);

    const [transactions, setTransactions] = useState<Array<Array<string>>>();
    const [formFilter] = useState<FormModel>({
        items: [
            {
                type: FormItemType.Date,
                name: "date",
                value: [(new Date()).setDate(now.getDate() - 7), new Date()],
                label: "",
                customProps: {minDate: (new Date()).setMonth(now.getMonth() - 6)},
                changeFunction: (item: FormItem) => {
                    if (item.value[0] != null && item.value[1] != null) {
                        getTransactions({
                            restaurantId: companyInfo?.sapId,
                            recordsPerPage: 10,
                            pageNumber: 1,
                            showActivities: true,
                            documentDateStart: format(item.value[0], "yyyy-MM-dd"),
                            documentDateEnd: format(item.value[1], "yyyy-MM-dd")
                        }, (arr: Array<Array<string>>, balance: string) => {
                            setTransactions(arr);
                        });
                    }
                }
            }
        ]
    });

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        now = new Date();
        getTransactions({
            restaurantId: companyInfo?.sapId,
            recordsPerPage: 10,
            pageNumber: 1,
            showActivities: true,
            documentDateStart: format(now.setDate(now.getDate() - 7), "yyyy-MM-dd"),
            documentDateEnd: format(new Date(), "yyyy-MM-dd")
        }, (arr: Array<Array<string>>, balance: string) => {
            setTransactions(arr);

        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Table
            size="full"
            title="İşlem Hareketlerim"
            headers={["Tarih", "ID", "Tutar", "İşlem Tipleri"]}
            errorText="Bu alan şu anda görüntülenememektedir. Daha sonra tekrar deneyiniz."
            classNames="mobile-full"
            items={transactions}
            filterForm={formFilter}
        />
    )
}

export default DashboardTransactions;