import Card from '../../../components/modules/card';
import Table from '../../../components/modules/table';
import {useEffect, useState} from 'react';
import {getFailedLogins} from '../../../actions/dashboardActions';
import {format} from 'date-fns';
import CookieCommonConsent from "../../../components/common/cookie-consent";

const DashboardHome = () => {
    const [failedLogins, setFailedLogins] = useState<Array<Array<string>>>();

    useEffect(() => {
        const currentDate = new Date();

        getFailedLogins({
            limit: 5,
            page_number: 1,
            type: 'LOGIN_ERROR',
            from_date: format(currentDate.setMonth(currentDate.getMonth() - 1), "dd-MM-yyyy"),
            to_date: format(new Date(), "dd-MM-yyyy")
        }, (arr: Array<Array<string>>) => {
            setFailedLogins(arr);
        })
    }, []);

    return (
        <>
            <div className="card-container">
                <Card
                    size=""
                    titleSize={2}
                    title="YemeksepetiPay ile Online Ödemelerini, Hızlı ve Güvenli Şekilde Al!"
                    text="Bu panel üzerinden YemeksepetiPay üye iş yeri bakiyenizi kolayca görüntüleyebilir, hesap hareketlerinizi takip edebilir ve hızlı ve güvenli bir şekilde ödemelerinizi alma talebinde bulunabilirsiniz."
                />
            </div>
            <Table
                size=""
                title="Son Hatalı Girişlerim"
                detailText="Tüm Hatalı Girişlerimi Göster"
                detailUrl="/dashboard/notifications"
                headers={["Tarih"]}
                errorText="Herhangi bir hatalı girişiniz bulunmamaktadır."
                items={failedLogins}
            />

        </>
    )
}

export default DashboardHome;