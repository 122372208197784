type ListProps = {
    items?: Array<string>,
    classNames?: string
}
const List = (props: ListProps) => {
    if (props.items && props.items.length > 0) {
        return (
            <ul className={props.classNames ?? ""}>
                {props.items.map((item, index) => (
                    <li key={"list-" + index} className="text s1">{item}</li>
                ))}
            </ul>
        )
    }

    return (null);
}

export default List;