import {FormItem, FormItemType, FormModel, ValidationType} from "./types";

export const validateFormModel = (formModel: FormModel) => {
    formModel.items.forEach((item, index) => {
        if (item.type !== FormItemType.CustomHtml) {
            validateFormItem(item, formModel);
        }
    });

    return formModel;
}

export const validateFormItem = (item: FormItem, formModel: FormModel) => {
    let sameWithItem = formModel.items.find(x => x.rules?.some(y => y.type === ValidationType.SameWith && y.value === item.name));

    if (sameWithItem) {
        validateFormItemSameWith(sameWithItem, formModel);
    }

    item.isValid = true;
    let hasRequiredRules = item.rules?.some(rule => rule.type === ValidationType.Required)
    if (item.rules && !(!hasRequiredRules && item.value.length === 0)) {
        item.rules.forEach((validation, index) => {
            validation.isValid = true;

            if (validation.type === ValidationType.ReCaptcha) {
                if (item.value.length === 0) {
                    validation.isValid = false;
                }
            }
            if (validation.type === ValidationType.Required) {
                if (item.type === FormItemType.Checkbox) {

                }
                if (item.value.length === 0) {
                    validation.isValid = false;
                }
            }
            if (validation.type === ValidationType.Email) {
                if (item.value.length > 0 && !(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(item.value))) {
                    validation.isValid = false;
                }
            }
            if (validation.type === ValidationType.MinLength) {
                if (item.value.length < validation.value) {
                    validation.isValid = false;
                }
            }
            if (validation.type === ValidationType.MaxLength) {
                if (item.value.length > validation.value) {
                    validation.isValid = false;
                }
            }
            if (validation.type === ValidationType.ExactLength) {
                if (item.value.length !== validation.value) {
                    validation.isValid = false;
                }
            }
            if (validation.type === ValidationType.SameWith) {
                if (!formModel.items.some(x => x.name === validation.value && x.value === item.value)) {
                    validation.isValid = false;
                }
            }
            if (validation.type === ValidationType.Custom) {
                if (validation.rule && !validation.rule(item)) {
                    validation.isValid = false;
                }
            }
            if (validation.type === ValidationType.TaxNo) {
                if (item.value.length > 0 && !(/^\d{10}$/.test(item.value))) {
                    validation.isValid = false;
                }
            }
            if (validation.type === ValidationType.IBAN) {
                if (item.value.length > 0 && !(/^TR\d{24}$/.test(item.value))) {
                    validation.isValid = false;
                }
            }
            if (validation.type === ValidationType.TCKN) {
                if (item.value.length > 0 && !(/^\d{11}$/.test(item.value))) {
                    validation.isValid = false;
                }
            }
            if (validation.type === ValidationType.GSM) {
                if (item.value.length > 0 && !(/^(05)([0-9]{2})\s?([0-9]{3})\s?([0-9]{2})\s?([0-9]{2})$/.test(item.value))) {
                    validation.isValid = false;
                }
            }


        });

        if (item.rules.some(x => x.isValid === false)) {
            item.isValid = false;
        }
    }

    return item;
}

export const validateFormItemSameWith = (item: FormItem, formModel: FormModel) => {
    item.isValid = true;
    if (item.rules) {
        item.rules.forEach((validation, index) => {
            validation.isValid = true;

            if (validation.type === ValidationType.SameWith) {
                if (!formModel.items.some(x => x.name === validation.value && x.value === item.value)) {
                    validation.isValid = false;
                }
            }
        });

        if (item.rules.some(x => x.isValid === false)) {
            item.isValid = false;
        }
    }

    return item;
}

export const validateButtons = (formModel: FormModel) => {
    let buttons = formModel.items.filter(x => x.type === FormItemType.Button && x.rules && x.rules.some(y => y.type === ValidationType.AllValidated));

    if (buttons.length) {
        let isValid = formModel.items.every((item, index) => {
            return item.type === FormItemType.CustomHtml || item.type === FormItemType.Button || item.isValid === true || !item.rules;
        });

        if (isValid && !buttons.every(x => x.isValid)) {
            buttons.forEach((item, index) => {
                item.isValid = true;
            });

            return true;
        } else if (!isValid && !buttons.every(x => !x.isValid)) {
            buttons.forEach((item, index) => {
                item.isValid = false;
            });

            return true;
        }
    }

    return false;
}