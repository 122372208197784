export type FormWrapperModel = {
    formModel: FormModel,
    submitFunction?: Function,
    errorFunction?: Function
}

export type FormModel = {
    items: Array<FormItem>,
    classNames?: string
}

export type FormItem = {
    type: FormItemType,
    name: string,
    value: any,
    isDisabled?: boolean,
    label?: string,
    isValid?: boolean,
    rules?: Array<Validation>,
    prevention?: Prevention,
    mask?: string | (string | RegExp)[],
    options?: Array<FormItemOption>,
    customValue?: any,
    customProps?: any,
    classNames?: string,
    containerClassNames?: string,
    changeFunction?: Function
}

export type FormItemOption = {
    value: any,
    label: string,
    selected?: boolean
}

export type Validation = {
    type: ValidationType,
    value?: any,
    text?: string,
    message?: string,
    isValid?: boolean,
    rule?: Function
}

export type Prevention = {
    type: PreventionType,
    rule?: Function
}

export enum PreventionType {
    OnlyNumber,
    OnlyText,
    Custom
}

export enum ValidationType {
    Required,
    Email,
    MinLength,
    MaxLength,
    ExactLength,
    SameWith,
    Custom,
    ReCaptcha,
    AllValidated,
    TaxNo,
    IBAN,
    TCKN,
    GSM
}

export enum FormItemType {
    Text,
    Number,
    Email,
    Password,
    Checkbox,
    Radio,
    Textarea,
    Select,
    SplitInput,
    File,
    Date,
    Button,
    CustomHtml,
    ReCaptcha
}