import './assets/shared.scss';
import MainRouter from './navigation/main';
import {useEffect} from 'react';
import {useAppSelector} from './store/hooks'
import {getLoadingCount, getMessage} from './store/globalSlice'
import {alca} from './alca/alca';
import Popup from './components/common/popup';
import {BrowserRouter} from 'react-router-dom';

function App() {
    useEffect(() => {
        alca.init();

        return () => {
            alca.deinit();
        }
    }, []);

    const loadingCount = useAppSelector(getLoadingCount);
    const message = useAppSelector(getMessage);

    return (
        <>
            <BrowserRouter>
                <MainRouter/>
            </BrowserRouter>
            {loadingCount > 0 &&
                <div className="loading">
                    <div className="loader"></div>
                </div>
            }
            {message &&
                <Popup {...message}></Popup>
            }
        </>
    );
}

export default App;
