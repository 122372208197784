import {FormItem} from "../models/types";
import ErrorMessage from './errorMessage';

type RadioProps = {
    item: FormItem,
    changeFunction: Function
}

const Radio = (props: RadioProps) => {
    if (props.item.options) {
        let disabled: any = {};

        if (props.item.isDisabled) {
            disabled["disabled"] = "disabled";
        }

        return (
            <div
                className={"form-item radio" + (props.item.value.length > 0 ? " active" : "") + (props.item.isValid === false ? " has-error" : "") + (props.item.classNames ? " " + props.item.classNames : "")}>
                {props.item.options.map((radio, index) => (
                    <div className="radio-item" key={"radio-" + index}>
                        <input
                            type="radio"
                            id={props.item.name + "-" + index}
                            name={props.item.name}
                            defaultValue={radio.value}
                            onChange={(e) => {
                                props.changeFunction(e.target.value, props.item.name)
                            }}
                            checked={radio.selected}
                            {...disabled}
                        />
                        <label className="button s3" htmlFor={props.item.name + "-" + index}>{radio.label}</label>
                    </div>
                ))}
                <ErrorMessage rules={props.item.rules}/>
            </div>
        )
    } else {
        return null;
    }
}

export default Radio;