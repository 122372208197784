import Button from '../../modules/button';
import Image from '../../modules/image';
import Title from '../../modules/title';
import Text from '../../modules/text';
import './content.scss';

type ContentProps = {
    image?: string,
    title?: string,
    text?: string,
    list?: Array<any>,
    btnPrimaryText?: string,
    btnPrimaryLink?: string
}

const MainContent = (props: ContentProps) => {
    return (
        <section className="content">
            <div className="container">
                <div className="box">
                    <Title size={4}>
                        {props.title}
                    </Title>
                    <Text size={2}>
                        {props.text}
                    </Text>
                    {props.list && props.list.length > 0 &&
                        <ul>
                            {props.list.map((item, index) => (
                                <li key={"content-list-" + index}>
                                    <Title size={3}>
                                        {item.title}
                                    </Title>
                                    <Text size={2}>
                                        {item.text}
                                    </Text>
                                </li>
                            ))}
                        </ul>
                    }
                    <Button text={props.btnPrimaryText} link={props.btnPrimaryLink} classNames="large"></Button>
                </div>
                <Image src={props.image} containerClass="image desktop-only"></Image>
            </div>
        </section>
    )
}

export default MainContent;