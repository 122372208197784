import Title from "./title";
import Text from "./text";
import Button from "./button";

type CardProps = {
    title?: string,
    text?: string,
    price?: string,
    buttonText?: string,
    buttonUrl?: string,
    size: "" | "large" | "small" | "full",
    titleSize?: number,
    classNames?: string
}
const Card = (props: CardProps) => {
    let titleSize = 4;

    if (props.titleSize) {
        titleSize = props.titleSize;
    }

    return (
        <div className={"card " + props.size + (props.classNames ? " " + props.classNames : "")}>
            <Title size={titleSize}>
                {props.title}
            </Title>
            <Text size={1}>
                {props.text}
            </Text>
            <Title size={4}>
                {props.price}
            </Title>
            <Button text={props.buttonText} link={props.buttonUrl} classNames="small"/>
        </div>
    )
}

export default Card;