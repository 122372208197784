import axios from "axios";
import {decrementLoadingCount, incrementLoadingCount, showMessage} from '../store/globalSlice';
import store from '../store/store';

const baseUrl = "https://production-yemekpay-com-bff.yemekpay.com/";

export type AxiosModel = {
    url: string,
    method: "post" | "get" | "put" | "delete",
    data?: object,
    params?: object,
    successFunc?: Function,
    errorFunc?: Function,
    preventLoading?: Boolean,
    preventMessage?: Boolean,
    isRetry?: Boolean,
    apiPath?: string
}

export const call = (model: AxiosModel) => {
    if (!model.preventLoading) {
        store.dispatch(incrementLoadingCount());
    }

    if (!model.apiPath) {
        model.apiPath = "api";
    }

    const headers: any = {};
    headers["Accept-Language"] = "tr";

    axios({
        method: model.method,
        headers: headers,
        data: model.data,
        params: model.params,
        url: baseUrl + model.apiPath.replaceAll("/", "") + "/" + model.url,
        withCredentials: true
    })
        .then(function (response: any) {
            if (!model.preventLoading) {
                store.dispatch(decrementLoadingCount());
            }

            if (!model.preventMessage && response.data && response.data.friendlyMessage) {
                store.dispatch(showMessage({
                    text: response.data.friendlyMessage,
                    btnPrimaryClassNames: (response.data && response.data.success ? "" : "secondary"),
                    btnPrimaryText: "Tamam"
                }));
            }

            if (response.data && response.data.success !== false) {
                if (model.successFunc) {
                    model.successFunc(response.data);
                }
            } else {
                if (model.errorFunc) {
                    model.errorFunc(response.data);
                }
            }
        })
        .catch(function (error: any) {
            if (error.message === "Network Error" || (error.response && error.response.status === 401)) {
                window.location.replace((new URL(error.config.url)).origin);
            }

            if (!model.preventLoading) {
                store.dispatch(decrementLoadingCount());
            }

            if (!model.preventMessage && error.response && error.response.data && error.response.data.friendlyMessage) {
                store.dispatch(showMessage({
                    text: error.response.data.friendlyMessage,
                    btnPrimaryClassNames: "secondary",
                    btnPrimaryText: "Tamam"
                }));
            } else if (!model.preventMessage || (error.response && error.response.status === 500)) {
                store.dispatch(showMessage({
                    text: "Bir hata oluştu, lütfen daha sonra tekrar deneyiniz.",
                    btnPrimaryClassNames: "secondary",
                    btnPrimaryText: "Tamam"
                }));
            }

            if (model.errorFunc && error && error.response) {
                model.errorFunc(error.response.data);
            }
        });
}