import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit';
import globalReducer from './globalSlice';


const customizedMiddleware = getDefaultMiddleware({
    serializableCheck: false
});

const store = configureStore({
    reducer: {
        global: globalReducer
    },
    middleware: customizedMiddleware
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store;