import {useEffect, useRef, useState} from 'react';
import './smsconfirm.scss';
import Title from '../../modules/title';
import Countdown, {CountdownRenderProps, CountdownTimeDelta} from 'react-countdown';
import Form from '../../form';
import {FormItemType, FormModel} from '../../form/models/types';

type SmsProps = {
    title: string,
    model: FormModel,
    countdownMiliSeconds: number,
    btnReSendActivateTime?: number,
    btnReSendCallback?: Function,
    btnBackCallback?: Function,
    classNames?: string
}

const Sms = (props: SmsProps) => {
    const countdownRef = useRef<Countdown>(null);
    const [formModel, setFormModel] = useState(props.model);
    const [isReSendActive, setIsReSendActive] = useState(false);
    const [countdownTime, setCountdownTime] = useState(props.countdownMiliSeconds);

    if (!props.btnReSendActivateTime) {
        props.btnReSendActivateTime = props.countdownMiliSeconds;
    }

    const renderer = (obj: CountdownRenderProps) => {
        if (obj.completed) {
            return <span>-</span>;
        } else {
            return <span>{obj.minutes < 10 ? "0" + obj.minutes : obj.minutes}:{obj.seconds < 10 ? "0" + obj.seconds : obj.seconds}</span>;
        }
    };

    const onTick = (timeDelta: CountdownTimeDelta) => {
        if (!isReSendActive && props.btnReSendActivateTime && props.countdownMiliSeconds - timeDelta.total >= props.btnReSendActivateTime) {
            setCountdownTime(timeDelta.total);
            setIsReSendActive(true);
        }
    }

    const onCountdownCompleted = () => {
        setIsReSendActive(true);

        let input = formModel.items.find(x => x.type == FormItemType.SplitInput);

        if (input) {
            input.isDisabled = true;
            setCountdownTime(0);
            setFormModel({...formModel});
        }
    }

    const onReSendClick = () => {
        setCountdownTime(props.countdownMiliSeconds);
        setIsReSendActive(false);

        if (countdownRef && countdownRef.current) {
            countdownRef.current.start();
        }

        if (props.btnReSendCallback) {
            props.btnReSendCallback();
        }

        let input = formModel.items.find(x => x.type == FormItemType.SplitInput);

        if (input) {
            input.isDisabled = false;
            setFormModel({...formModel});
        }
    }

    const onBackClick = () => {
        if (props.btnBackCallback) {
            props.btnBackCallback();
        }
    }

    return (
        <div className="form">
            <Title size={4}>
                {props.title}
            </Title>
            <Form key={"form-sms"} formModel={formModel}/>
            <div className='sms-countdown text s3'>
                <span>Kod gelmediyse süre bitiminde tekrar deneyin</span>
                <Countdown ref={countdownRef} autoStart={true} date={Date.now() + countdownTime} renderer={renderer}
                           onComplete={onCountdownCompleted} onTick={onTick}/>
            </div>
            <button className="btn large" type="button" title="Tekrar Gönder" disabled={!isReSendActive}
                    onClick={onReSendClick}>Tekrar Gönder
            </button>
            <button className="btn tertiary blue no-border large back" type="button" title="Geri"
                    onClick={onBackClick}>Geri
            </button>
        </div>
    )
}

export default Sms;