import React, {useState} from "react";
import {contactDashboard} from "../../../actions/dashboardActions";
import Form from "../../../components/form";
import {FormItemType, FormModel, ValidationType} from "../../../components/form/models/types";
import Text from "../../../components/modules/text";
import Title from "../../../components/modules/title";
import CookieCommonConsent from "../../../components/common/cookie-consent";

const DashboardContact = () => {
    const [formModel] = useState<FormModel>({
        items: [
            {
                type: FormItemType.Select,
                name: "title",
                value: "",
                label: "Konu Başlığı",
                options: [
                    {label: 'Başvuru', value: 'Başvuru'},
                    {label: 'Bilgi Değişikliği', value: 'Bilgi Değişikliği'},
                    {label: 'Ödemeler / Tahsilat', value: 'Ödemeler / Tahsilat'},
                    {label: 'Diğer', value: 'Diğer'}
                ]
            },
            {
                type: FormItemType.Textarea,
                name: "message",
                value: "",
                label: "Açıklama Giriniz *",
                rules: [
                    {type: ValidationType.Required},
                    {
                        type: ValidationType.MaxLength,
                        value: 1500,
                        message: "*Maksimum 1500 karakter girmeniz gerekmektedir "
                    },
                    {
                        type: ValidationType.MinLength,
                        value: 30,
                        message: "*Minimum 30 karakter girmeniz gerekmektedir "
                    }]
            },
            {
                type: FormItemType.Button,
                name: "submit",
                label: "Gönder",
                classNames: "large",
                value: "",
                rules: [{type: ValidationType.AllValidated}]
            }
        ]
    });

    const formSubmitFunction = (model: any) => {
        contactDashboard(model);
    }

    return (
        <>
            <Title size={4}>
                Bize Ulaşın
            </Title>

            <div className="dashboard-form">
                <div className="form">
                    <Text size={2}>
                        Aşağıdaki iletişim formunu doldurarak bize ulaşabilirsiniz. İlgili birimlerimiz mesajınızı
                        inceledikten sonra, en kısa sürede size dönüş sağlayacaktır.
                    </Text>
                    <Form key={"dashboard-contact"} formModel={formModel} submitFunction={formSubmitFunction}/>
                    <div className="text s3">
                        Kişisel verileriniz, talebinizin gerçekleştirilmesi amacıyla 6698 Sayılı Kanun kapsamında
                        işlenmektedir. Kişisel verileriniz ile ilgili Aydınlatma Metnine <a href="/"
                                                                                            title="KVKK Aydınlatma metni"
                                                                                            target="_blank"><b>buradan</b></a> ulaşabilirsiniz.
                    </div>
                </div>
            </div>


        </>
    )
}

export default DashboardContact;