import {useCallback, useEffect, useState} from 'react';
import './form.scss';
import Form from '../../../components/form';
import {
    FormItem,
    FormItemOption,
    FormItemType,
    FormModel,
    PreventionType,
    ValidationType
} from '../../../components/form/models/types';
import Title from '../../../components/modules/title';
import Text from '../../../components/modules/text';
import Image from '../../../components/modules/image';
import {getCities, getCounties, getDistricts, getNationalities} from '../../../actions/globalActions';
import {createCompany, getUserProfile, uploadFiles} from '../../../actions/dashboardActions';
import CookieCommonConsent from "../../../components/common/cookie-consent";

const OnboardingFormPage = () => {
    const [step, setStep] = useState(0);
    const [isValid, setValid] = useState(true);
    const [userData, setUserData] = useState<any>(null);
    const [currentTab, setCurrentTab] = useState(0);
    const [formPostData, setFormPostData] = useState({});

    const [formModelStep1, setFormModelStep1] = useState<FormModel>({
        items: [
            {
                type: FormItemType.Text,
                name: "title",
                value: "",
                label: "Tüzel Kişi Ünvanı*",
                rules: [
                    {type: ValidationType.Required},
                    {
                        type: ValidationType.MaxLength,
                        value: 64,
                        message: "*Maksimum 64 karakter girmeniz gerekmektedir "
                    },
                    {type: ValidationType.MinLength, value: 3, message: "*Minimum 3 karakter girmeniz gerekmektedir "}
                ]
            },
            {
                type: FormItemType.Text,
                name: "firstName",
                value: "",
                label: "Yetkilinin Adı*",
                classNames: "mobile-half",
                isDisabled: true
            },
            {
                type: FormItemType.Text,
                name: "lastName",
                value: "",
                label: "Yetkilinin Soyadı*",
                classNames: "mobile-half",
                isDisabled: true
            },
            {
                type: FormItemType.Text,
                name: "birthDate",
                value: "",
                label: "Yetkilinin Doğum Tarihi*",
                mask: "9999-99-99",
                rules: [
                    {type: ValidationType.Required},
                    {
                        type: ValidationType.Custom, rule: (item: FormItem) => {
                            return item.value.replace(/_/g, "").length === 10;
                        }
                    },
                    {
                        type: ValidationType.Custom,
                        message: "Lütfen geçerli bir tarih girin.",
                        rule: (item: FormItem) => {
                            const val = item.value.split("-");
                            return val[2] <= 31 && val[1] <= 12 && val[0] < (new Date()).getFullYear() && val[0] > 1900;
                        }
                    }
                ]
            },
            {
                type: FormItemType.Select,
                name: "nationalityCode",
                value: "",
                label: "Yetkilinin Uyruk Bilgisi*",
                rules: [
                    {type: ValidationType.Required}
                ]
            },
            {
                type: FormItemType.Text,
                name: "tcNumber",
                value: "",
                label: "Yetkilinin TC Kimlik Numarası*",
                rules: [
                    {type: ValidationType.Required},
                    {
                        type: ValidationType.ExactLength,
                        value: 11,
                        message: "TC Kimlik numarası 11 karakter içermelidir."
                    }
                ],
                prevention: {type: PreventionType.OnlyNumber}
            },
            {
                type: FormItemType.Text,
                name: "mobileNumber",
                value: "",
                label: "Yetkilinin Cep Telefonu*",
                isDisabled: true
            },
            {
                type: FormItemType.Email,
                name: "email",
                value: "",
                label: "Yetkilinin E-postası*",
                isDisabled: true
            },
            {
                type: FormItemType.Text,
                name: "companyName",
                value: "",
                label: "Şirket Adı*",
                rules: [
                    {type: ValidationType.Required}
                ]
            },
            {
                type: FormItemType.CustomHtml,
                name: "",
                value:
                    <div className="actions">
                        <div className="container">
                            <button className="btn large tertiary no-bg no-border blue" type="button" title="Geri"
                                    style={{visibility: "hidden"}}><Image
                                src={require('../../../assets/Icons/arrow-left.svg').default}/> Geri
                            </button>
                            <button className="btn large" type="submit" title="Devam">Devam <Image
                                src={require('../../../assets/Icons/arrow-right-white.svg').default}/></button>
                        </div>
                    </div>
            }
        ]
    });

    const step1SubmitFunction = useCallback((model: any) => {
        setFormPostData({...model});
        setValid(true);
        setStep(1);
    }, [userData]);

    const step1ErrorFunction = (model: FormModel) => {
        setValid(false);
    }

    const [formModelStep2, setFormModelStep2] = useState<FormModel>({
        items: [
            {
                type: FormItemType.CustomHtml,
                name: "",
                value:
                    <Title size={5}>
                        Şirket Türü
                    </Title>
            },
            {
                type: FormItemType.Radio,
                name: "companyTypeCode",
                value: "",
                label: "",
                rules: [
                    {type: ValidationType.Required}
                ],
                options: [{label: 'Limited Şirket', value: 1}, {
                    label: 'Şahıs Şirketi',
                    value: 2
                }, {label: 'Anonim Şirket', value: 3}]
            },
            {
                type: FormItemType.CustomHtml,
                name: "",
                value:
                    <Title size={5}>
                        Ticari Bilgiler
                    </Title>
            },
            {
                type: FormItemType.Text,
                name: "tradeRegisterNumber",
                value: "",
                label: "Ticaret Sicil Numarası*",
                rules: [
                    {type: ValidationType.Required},
                    {type: ValidationType.ExactLength, value: 9, message: "Bu alan 9 karakter içermelidir."}
                ]
            },
            {
                type: FormItemType.Text,
                name: "taxNumber",
                value: "",
                label: "Vergi Kimlik Numarası*",
                rules: [
                    {type: ValidationType.Required},
                    {
                        type: ValidationType.TaxNo,
                        message: "*Tam olarak 10 rakam girmeniz gerekmektedir",
                        isValid: true
                    },
                ]
            },
            {
                type: FormItemType.Text,
                name: "operationOfArea",
                value: "",
                label: "Faaliyet Konusu*",
                rules: [
                    {type: ValidationType.Required},
                    {
                        type: ValidationType.MaxLength,
                        value: 64,
                        message: "*Maksimum 64 karakter girmeniz gerekmektedir "
                    },
                    {type: ValidationType.MinLength, value: 3, message: "*Minimum 3 karakter girmeniz gerekmektedir "}
                ]
            },
            {
                type: FormItemType.Text,
                name: "iban",
                value: "",
                label: "IBAN*",
                mask: "TR999999999999999999999999",
                rules: [
                    {
                        type: ValidationType.IBAN,
                        message: "*Geçerli bir IBAN giriniz",
                        isValid: true
                    }
                ]
            },
            {
                type: FormItemType.CustomHtml,
                name: "",
                value:
                    <Title size={5}>
                        Adres Bilgileri
                    </Title>
            },
            {
                type: FormItemType.Select,
                name: "cityCode",
                value: "",
                label: "İl*",
                rules: [
                    {type: ValidationType.Required}
                ],
                changeFunction: (item: FormItem) => {
                    var countySelect = formModelStep2.items.find(x => x.name === "countyCode");
                    var districtSelect = formModelStep2.items.find(x => x.name === "districtCode");

                    getCounties(item.value, ((options: Array<FormItemOption>) => {
                        if (districtSelect) {
                            districtSelect.value = "";
                        }

                        if (countySelect) {
                            countySelect.value = "";
                            countySelect.options = options;
                            countySelect.isDisabled = false;

                            setFormModelStep2(formModelStep2);
                        }
                    }));
                }
            },
            {
                type: FormItemType.Select,
                name: "countyCode",
                value: "",
                label: "İlçe*",
                rules: [
                    {type: ValidationType.Required}
                ],
                changeFunction: (item: FormItem) => {
                    var districtSelect = formModelStep2.items.find(x => x.name === "districtCode");

                    getDistricts(item.value, ((options: Array<FormItemOption>) => {
                        if (districtSelect) {
                            districtSelect.options = options;
                            districtSelect.isDisabled = false;

                            setFormModelStep2(formModelStep2);
                        }
                    }));
                }
            },
            {
                type: FormItemType.Select,
                name: "districtCode",
                value: "",
                label: "Semt*",
                rules: [
                    {type: ValidationType.Required}
                ]
            },
            {
                type: FormItemType.Text,
                name: "openAddress",
                value: "",
                label: "Açık Adres*",
                classNames: "full",
                rules: [
                    {type: ValidationType.Required},
                    {type: ValidationType.MinLength, value: 10},
                    {type: ValidationType.MaxLength, value: 1500}
                ]
            },
            {
                type: FormItemType.CustomHtml,
                name: "",
                value:
                    <div className="actions">
                        <div className="container">
                            <button className="btn large tertiary no-bg no-border blue" type="button" title="Geri"
                                    onClick={() => {
                                        setStep(0);
                                        setValid(false);
                                    }}><Image src={require('../../../assets/Icons/arrow-left.svg').default}/> Geri
                            </button>
                            <button className="btn large" type="submit" title="Devam">Devam <Image
                                src={require('../../../assets/Icons/arrow-right-white.svg').default}/></button>
                        </div>
                    </div>
            }
        ]
    });

    const step2SubmitFunction = useCallback((model: any) => {
        setFormPostData({...formPostData, ...model});
        setValid(true);
        setStep(2);
    }, [formPostData]);

    const step2ErrorFunction = (model: FormModel) => {
        setValid(false);
    }

    const [formModelStep3, setFormModelStep3] = useState<FormModel>({
        items: [
            {
                type: FormItemType.File,
                name: "tax_board",
                value: "",
                label: "Vergi Levhası*",
                classNames: "mobile-half",
                rules: [
                    {type: ValidationType.Required}
                ]
            },
            {
                type: FormItemType.File,
                name: "signatory_circular",
                value: "",
                label: "İmza Sirküleri*",
                classNames: "mobile-half",
                rules: [
                    {type: ValidationType.Required}
                ]
            },
            {
                type: FormItemType.File,
                name: "identify_proof",
                value: "",
                label: "Kimlik Fotokopisi*",
                classNames: "mobile-half",
                rules: [
                    {type: ValidationType.Required}
                ]
            },
            {
                type: FormItemType.CustomHtml,
                name: "",
                value:
                    <div className="actions">
                        <div className="container">
                            <button className="btn large tertiary no-bg no-border blue desktop-only" type="button"
                                    title="Geri" onClick={() => {
                                setStep(1);
                                setValid(false);
                            }}><Image src={require('../../../assets/Icons/arrow-left.svg').default}/> Geri
                            </button>
                            <button className="btn large" type="submit" title="Devam">Devam <Image
                                src={require('../../../assets/Icons/arrow-right-white.svg').default}/></button>
                        </div>
                    </div>
            }
        ]
    });

    const step3SubmitFunction = useCallback((model: any) => {
        let formData = new FormData();
        let taxBoard = document.querySelector('form input[type="file"][name="tax_board"]') as HTMLInputElement;
        let signatory = document.querySelector('form input[type="file"][name="signatory_circular"]') as HTMLInputElement;
        let identity = document.querySelector('form input[type="file"][name="identify_proof"]') as HTMLInputElement;

        if (taxBoard && taxBoard.files && taxBoard.files.length > 0) {
            formData.append("tax_board", taxBoard.files[0]);
        }
        if (signatory && signatory.files && signatory.files.length > 0) {
            formData.append("signatory_circular", signatory.files[0]);
        }
        if (identity && identity.files && identity.files.length > 0) {
            formData.append("identify_proof", identity.files[0]);
        }

        formData.append("user_id", userData.userId);

        uploadFiles(formData, (response: any) => {
            let documentList = [];

            for (let i = 0; i < response.payload.length; i++) {
                documentList.push({
                    categoryCode: (response.payload[i].fileType === "TAX_BOARD" ? 5 : (response.payload[i].fileType === "IDENTIFY_PROOF" ? 6 : 7)),
                    ftpUrl: response.payload[i].fileUrl
                });
            }

            setFormPostData({...formPostData, documentList: documentList});

            setValid(true);
            setStep(3);
        });
    }, [userData, formPostData]);

    const step3ErrorFunction = (model: FormModel) => {
        setValid(false);
    }

    const [formModelStep4, setFormModelStep4] = useState<FormModel>({
        items: [
            {
                type: FormItemType.Checkbox,
                name: "userConsent",
                value: "",
                label: "YemeksepetiPay Hizmet Şartları'nı, KVKK Aydınlatma ve Rıza Metni'ni ve Üye İş Yeri Sözleşmesi‘ni okudum ve kabul ediyorum.",
                classNames: "full",
                rules: [
                    {type: ValidationType.Required, message: "Üyeliğinizi tamamlayabilmek için kutucuğu işaretleyin"}
                ]
            },
            {
                type: FormItemType.CustomHtml,
                name: "",
                value:
                    <div className="actions">
                        <div className="container">
                            <button className="btn large tertiary no-bg no-border blue" type="button" title="Geri"
                                    onClick={() => {
                                        setStep(2);
                                        setValid(false);
                                    }}><Image src={require('../../../assets/Icons/arrow-left.svg').default}/> Geri
                            </button>
                            <button className="btn large" type="submit" title="Üyeliği Tamamla"><Image
                                src={require('../../../assets/Icons/user-white.svg').default}/> Üyeliği Tamamla
                            </button>
                        </div>
                    </div>
            }
        ]
    });

    const step4SubmitFunction = useCallback((model: any) => {
        createCompany(formPostData, (response: any) => {
            setValid(true);
            setStep(4);
        });
    }, [formPostData]);

    const step4ErrorFunction = (model: FormModel) => {
        setValid(false);
    }

    useEffect(() => {
        getUserProfile((response: any) => {
            setUserData(response.payload);

            if (response.payload) {
                var firstName = formModelStep1.items.find(p => p.name == "firstName");
                var lastName = formModelStep1.items.find(p => p.name == "lastName");
                var mobileNumber = formModelStep1.items.find(p => p.name == "mobileNumber");
                var email = formModelStep1.items.find(p => p.name == "email");

                if (firstName) {
                    firstName.value = response.payload.firstName;
                }

                if (lastName) {
                    lastName.value = response.payload.lastName;
                }

                if (mobileNumber) {
                    mobileNumber.value = response.payload.mobileNumber;
                }

                if (email) {
                    email.value = response.payload.email;
                }

                getNationalities((options: Array<FormItemOption>) => {
                    var nationality = formModelStep1.items.find(x => x.name === "nationalityCode");

                    if (nationality) {
                        nationality.options = options;

                        setFormModelStep1(formModelStep1);
                    }
                });
                // eslint-disable-next-line react-hooks/exhaustive-deps
            }
        });

        getCities((options: Array<FormItemOption>) => {
            var citySelect = formModelStep2.items.find(x => x.name === "cityCode");
            if (citySelect) {
                citySelect.options = options;
                setFormModelStep2({...formModelStep2});
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <section className={"onboarding-form" + (step > 3 ? " no-pb" : "")}>
            {userData &&
                <>
                    {step < 4 ?
                        <>
                            <div className="steps">
                                <div className="container">
                                    <div
                                        className={"step button s1" + (step === 0 ? " active" + (!isValid ? " error" : "") : " valid")}>
                                        Yetkili Bilgileri
                                    </div>
                                    <div
                                        className={"step button s1" + (step === 1 ? " active" + (!isValid ? " error" : "") : step > 1 ? " valid" : "")}>
                                        Ticari Bilgiler
                                    </div>
                                    <div
                                        className={"step button s1" + (step === 2 ? " active" + (!isValid ? " error" : "") : step > 2 ? " valid" : "")}>
                                        Belge Yükle
                                    </div>
                                    <div
                                        className={"step button s1" + (step === 3 ? " active" + (!isValid ? " error" : "") : step > 3 ? " valid" : "")}>
                                        Sözleşmeler
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                {step === 0 && formModelStep1 &&
                                    <div className="form">
                                        <Title size={4}>
                                            1. Yetkili Bilgileri
                                        </Title>
                                        <Text size={2}>
                                            Şirket yetkilisinin bilgilerini eksiksiz girdikten sonra, Ticari Bilgiler
                                            aşamasına geçebilirsiniz.
                                            <br/>
                                            Buradaki bilgileriniz üzerinden gerekli güvenlik kontrolleri ve 5549 Sayılı
                                            Kanun kapsamında kimlik tespitiniz yapılacağından lütfen eksiksiz ve doğru
                                            bilgi paylaşınız.
                                        </Text>
                                        <Form key={"onboarding-form-step-1"} formModel={formModelStep1}
                                              submitFunction={step1SubmitFunction} errorFunction={step1ErrorFunction}/>
                                    </div>
                                }
                                {step === 1 && formModelStep2 &&
                                    <div className="form">
                                        <Title size={4}>
                                            2. Ticari Bilgiler
                                        </Title>
                                        <Text size={2}>
                                            Şirket bilgilerinizi eksiksiz girdikten sonra, Belge Yükle aşamasına
                                            geçebilirsiniz.
                                        </Text>
                                        <Form key={"onboarding-form-step-2"} formModel={formModelStep2}
                                              submitFunction={step2SubmitFunction} errorFunction={step2ErrorFunction}/>
                                    </div>
                                }
                                {step === 2 && formModelStep3 &&
                                    <div className="form">
                                        <Title size={4}>
                                            3. Belge Yükle
                                        </Title>
                                        <Text size={2}>
                                            İstenen belgeleri PNG, JPEG veya PDF formatında eksiksiz yükledikten sonra,
                                            Kimlik Doğrulaması aşamasına geçebilirsiniz.
                                            <br/>
                                            Sisteme yüklediğiniz imza sirkülerindeki yetkili kişilerden biri değilseniz
                                            başvurunuz reddolacaktır. Bu nedenle lütfen yetkilendirildiğiniz güncel bir
                                            sirküler ile ilerleyiniz.
                                        </Text>
                                        <Form key={"onboarding-form-step-3"} formModel={formModelStep3}
                                              submitFunction={step3SubmitFunction} errorFunction={step3ErrorFunction}/>
                                    </div>
                                }
                                {step === 3 && formModelStep4 &&
                                    <div className="form">
                                        <Title size={4}>
                                            4. Sözleşmeler
                                        </Title>
                                        <Text size={2}>
                                            YemeksepetiPay A.Ş.’ye üye olmak isteyen şirket yetkililerinin, sözleşmeleri
                                            okumaları ve onaylamaları gerekmektedir.
                                        </Text>
                                        <div className="tabs">
                                            <div className="tab-menu">
                                                <a href="javascript:void(0)" title="YemeksepetiPay Hizmet Şartları"
                                                   onClick={() => {
                                                       setCurrentTab(0)
                                                   }}>
                                                    <span className={currentTab === 0 ? "title s6" : "text s3"}>YemeksepetiPay Hizmet Şartları</span>
                                                </a>
                                                <a href="javascript:void(0)" title="KVKK Aydıntlatma ve Rıza Metni"
                                                   onClick={() => {
                                                       setCurrentTab(1)
                                                   }}>
                                                    <span className={currentTab === 1 ? "title s6" : "text s3"}>KVKK Aydıntlatma ve Rıza Metni</span>
                                                </a>
                                                <a href="javascript:void(0)" title="Üye İş Yeri Sözleşmesi"
                                                   onClick={() => {
                                                       setCurrentTab(2)
                                                   }}>
                                                    <span className={currentTab === 2 ? "title s6" : "text s3"}>Üye İş Yeri Sözleşmesi</span>
                                                </a>
                                            </div>
                                            <div className="tab-items">
                                                {currentTab === 0 &&
                                                    <div className="tab-item">
                                                        <Title size={3}>
                                                            YemeksepetiPay Hizmet Şartları
                                                        </Title>
                                                        <Text size={3}>
                                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                            Suspendisse ut nibh non dolor imperdiet luctus. Phasellus
                                                            urna elit, porttitor vitae imperdiet nec, sollicitudin eget
                                                            dui. Vestibulum non est in felis malesuada faucibus. Cras
                                                            dolor magna, elementum eu fringilla sit amet, condimentum eu
                                                            dui. Suspendisse faucibus ultricies mollis. Morbi sed
                                                            venenatis neque. Cras id libero sit amet mauris tempus
                                                            sodales. Pellentesque scelerisque odio nec ipsum eleifend
                                                            tempus. Mauris lobortis rutrum pretium. Class aptent taciti
                                                            sociosqu ad litora torquent per conubia nostra, per inceptos
                                                            himenaeos. Aenean sollicitudin ut nisi et posuere.
                                                            Vestibulum dolor erat, venenatis malesuada luctus facilisis,
                                                            malesuada non arcu. Phasellus eu libero sit amet eros
                                                            sollicitudin laoreet id vitae tellus. Aenean vitae quam eget
                                                            nisi sollicitudin dictum at quis est. Proin a arcu leo. Sed
                                                            lacus quam, pulvinar nec mollis a, placerat gravida erat.
                                                            Praesent at luctus sapien. Etiam eu tellus vel metus pretium
                                                            mattis. Integer et magna et magna malesuada mollis. Nulla
                                                            eget nisl faucibus, malesuada sapien nec, scelerisque metus.
                                                            Morbi diam nisl, gravida eget risus a, ultrices efficitur
                                                            tellus. Proin quis tellus lorem. Nullam eu magna velit.
                                                            Nulla facilisi. Donec congue ipsum ac erat pretium
                                                            efficitur.Lorem ipsum dolor sit amet, consectetur adipiscing
                                                            elit. Suspendisse ut nibh non dolor imperdiet luctus.
                                                            Phasellus urna elit, porttitor vitae imperdiet nec,
                                                            sollicitudin eget dui. Vestibulum non est in felis malesuada
                                                            faucibus. Cras dolor magna, elementum eu fringilla sit amet,
                                                            condimentum eu dui. Suspendisse faucibus ultricies mollis.
                                                            Morbi sed venenatis neque. Cras id libero sit amet mauris
                                                            tempus sodales. Pellentesque scelerisque odio nec ipsum
                                                            eleifend tempus. Mauris lobortis rutrum pretium. Class
                                                            aptent taciti sociosqu ad litora torquent per conubia
                                                            nostra, per inceptos himenaeos. Aenean sollicitudin ut nisi
                                                            et posuere. Vestibulum dolor erat, venenatis malesuada
                                                            luctus facilisis, malesuada non arcu. Phasellus eu libero
                                                            sit amet eros sollicitudin laoreet id vitae tellus. Aenean
                                                            vitae quam eget nisi sollicitudin dictum at quis est. Proin
                                                            a arcu leo. Sed lacus quam, pulvinar nec mollis a, placerat
                                                            gravida erat. Praesent at luctus sapien. Etiam eu tellus vel
                                                            metus pretium mattis. Integer et magna et magna malesuada
                                                            mollis. Nulla eget nisl faucibus, malesuada sapien nec,
                                                            scelerisque metus. Morbi diam nisl, gravida eget risus a,
                                                            ultrices efficitur tellus. Proin quis tellus lorem. Nullam
                                                            eu magna velit. Nulla facilisi. Donec congue ipsum ac erat
                                                            pretium efficitur.Pellentesque suscipit eget enim vitae
                                                            ultricies. Nam posuere massa urna, a tempor diam ullamcorper
                                                            non. Donec egestas porta luctus. Cras cursus, ipsum in
                                                            tempor tristique.
                                                        </Text>
                                                    </div>
                                                }
                                                {currentTab === 1 &&
                                                    <div className="tab-item">
                                                        <Title size={3}>
                                                            KVKK Aydıntlatma ve Rıza Metni
                                                        </Title>
                                                        <Text size={3}>
                                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                            Suspendisse ut nibh non dolor imperdiet luctus. Phasellus
                                                            urna elit, porttitor vitae imperdiet nec, sollicitudin eget
                                                            dui. Vestibulum non est in felis malesuada faucibus. Cras
                                                            dolor magna, elementum eu fringilla sit amet, condimentum eu
                                                            dui. Suspendisse faucibus ultricies mollis. Morbi sed
                                                            venenatis neque. Cras id libero sit amet mauris tempus
                                                            sodales. Pellentesque scelerisque odio nec ipsum eleifend
                                                            tempus. Mauris lobortis rutrum pretium. Class aptent taciti
                                                            sociosqu ad litora torquent per conubia nostra, per inceptos
                                                            himenaeos. Aenean sollicitudin ut nisi et posuere.
                                                            Vestibulum dolor erat, venenatis malesuada luctus facilisis,
                                                            malesuada non arcu. Phasellus eu libero sit amet eros
                                                            sollicitudin laoreet id vitae tellus. Aenean vitae quam eget
                                                            nisi sollicitudin dictum at quis est. Proin a arcu leo. Sed
                                                            lacus quam, pulvinar nec mollis a, placerat gravida erat.
                                                            Praesent at luctus sapien. Etiam eu tellus vel metus pretium
                                                            mattis. Integer et magna et magna malesuada mollis. Nulla
                                                            eget nisl faucibus, malesuada sapien nec, scelerisque metus.
                                                            Morbi diam nisl, gravida eget risus a, ultrices efficitur
                                                            tellus. Proin quis tellus lorem. Nullam eu magna velit.
                                                            Nulla facilisi. Donec congue ipsum ac erat pretium
                                                            efficitur.Lorem ipsum dolor sit amet, consectetur adipiscing
                                                            elit. Suspendisse ut nibh non dolor imperdiet luctus.
                                                            Phasellus urna elit, porttitor vitae imperdiet nec,
                                                            sollicitudin eget dui. Vestibulum non est in felis malesuada
                                                            faucibus. Cras dolor magna, elementum eu fringilla sit amet,
                                                            condimentum eu dui. Suspendisse faucibus ultricies mollis.
                                                            Morbi sed venenatis neque. Cras id libero sit amet mauris
                                                            tempus sodales. Pellentesque scelerisque odio nec ipsum
                                                            eleifend tempus. Mauris lobortis rutrum pretium. Class
                                                            aptent taciti sociosqu ad litora torquent per conubia
                                                            nostra, per inceptos himenaeos. Aenean sollicitudin ut nisi
                                                            et posuere. Vestibulum dolor erat, venenatis malesuada
                                                            luctus facilisis, malesuada non arcu. Phasellus eu libero
                                                            sit amet eros sollicitudin laoreet id vitae tellus. Aenean
                                                            vitae quam eget nisi sollicitudin dictum at quis est. Proin
                                                            a arcu leo. Sed lacus quam, pulvinar nec mollis a, placerat
                                                            gravida erat. Praesent at luctus sapien. Etiam eu tellus vel
                                                            metus pretium mattis. Integer et magna et magna malesuada
                                                            mollis. Nulla eget nisl faucibus, malesuada sapien nec,
                                                            scelerisque metus. Morbi diam nisl, gravida eget risus a,
                                                            ultrices efficitur tellus. Proin quis tellus lorem. Nullam
                                                            eu magna velit. Nulla facilisi. Donec congue ipsum ac erat
                                                            pretium efficitur.Pellentesque suscipit eget enim vitae
                                                            ultricies. Nam posuere massa urna, a tempor diam ullamcorper
                                                            non. Donec egestas porta luctus. Cras cursus, ipsum in
                                                            tempor tristique.
                                                        </Text>
                                                    </div>
                                                }
                                                {currentTab === 2 &&
                                                    <div className="tab-item">
                                                        <Title size={3}>
                                                            Üye İş Yeri Sözleşmesi
                                                        </Title>
                                                        <Text size={3}>
                                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                            Suspendisse ut nibh non dolor imperdiet luctus. Phasellus
                                                            urna elit, porttitor vitae imperdiet nec, sollicitudin eget
                                                            dui. Vestibulum non est in felis malesuada faucibus. Cras
                                                            dolor magna, elementum eu fringilla sit amet, condimentum eu
                                                            dui. Suspendisse faucibus ultricies mollis. Morbi sed
                                                            venenatis neque. Cras id libero sit amet mauris tempus
                                                            sodales. Pellentesque scelerisque odio nec ipsum eleifend
                                                            tempus. Mauris lobortis rutrum pretium. Class aptent taciti
                                                            sociosqu ad litora torquent per conubia nostra, per inceptos
                                                            himenaeos. Aenean sollicitudin ut nisi et posuere.
                                                            Vestibulum dolor erat, venenatis malesuada luctus facilisis,
                                                            malesuada non arcu. Phasellus eu libero sit amet eros
                                                            sollicitudin laoreet id vitae tellus. Aenean vitae quam eget
                                                            nisi sollicitudin dictum at quis est. Proin a arcu leo. Sed
                                                            lacus quam, pulvinar nec mollis a, placerat gravida erat.
                                                            Praesent at luctus sapien. Etiam eu tellus vel metus pretium
                                                            mattis. Integer et magna et magna malesuada mollis. Nulla
                                                            eget nisl faucibus, malesuada sapien nec, scelerisque metus.
                                                            Morbi diam nisl, gravida eget risus a, ultrices efficitur
                                                            tellus. Proin quis tellus lorem. Nullam eu magna velit.
                                                            Nulla facilisi. Donec congue ipsum ac erat pretium
                                                            efficitur.Lorem ipsum dolor sit amet, consectetur adipiscing
                                                            elit. Suspendisse ut nibh non dolor imperdiet luctus.
                                                            Phasellus urna elit, porttitor vitae imperdiet nec,
                                                            sollicitudin eget dui. Vestibulum non est in felis malesuada
                                                            faucibus. Cras dolor magna, elementum eu fringilla sit amet,
                                                            condimentum eu dui. Suspendisse faucibus ultricies mollis.
                                                            Morbi sed venenatis neque. Cras id libero sit amet mauris
                                                            tempus sodales. Pellentesque scelerisque odio nec ipsum
                                                            eleifend tempus. Mauris lobortis rutrum pretium. Class
                                                            aptent taciti sociosqu ad litora torquent per conubia
                                                            nostra, per inceptos himenaeos. Aenean sollicitudin ut nisi
                                                            et posuere. Vestibulum dolor erat, venenatis malesuada
                                                            luctus facilisis, malesuada non arcu. Phasellus eu libero
                                                            sit amet eros sollicitudin laoreet id vitae tellus. Aenean
                                                            vitae quam eget nisi sollicitudin dictum at quis est. Proin
                                                            a arcu leo. Sed lacus quam, pulvinar nec mollis a, placerat
                                                            gravida erat. Praesent at luctus sapien. Etiam eu tellus vel
                                                            metus pretium mattis. Integer et magna et magna malesuada
                                                            mollis. Nulla eget nisl faucibus, malesuada sapien nec,
                                                            scelerisque metus. Morbi diam nisl, gravida eget risus a,
                                                            ultrices efficitur tellus. Proin quis tellus lorem. Nullam
                                                            eu magna velit. Nulla facilisi. Donec congue ipsum ac erat
                                                            pretium efficitur.Pellentesque suscipit eget enim vitae
                                                            ultricies. Nam posuere massa urna, a tempor diam ullamcorper
                                                            non. Donec egestas porta luctus. Cras cursus, ipsum in
                                                            tempor tristique.
                                                        </Text>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <Form key={"onboarding-form-step-4"} formModel={formModelStep4}
                                              submitFunction={step4SubmitFunction} errorFunction={step4ErrorFunction}/>
                                    </div>
                                }
                            </div>
                        </>
                        :
                        <div className="result">
                            <div className="content">
                                <Image src={require('../../../assets/Icons/onboarding-success.svg').default}/>
                                <Title size={4}>
                                    Başvurunuzu Tamamladınız
                                </Title>
                                <Text size={2}>
                                    Başvuru formunda verdiğiniz bilgilerin doğruluğunu kontrol süreci ortalama 1 iş günü
                                    sürmektedir. Başvuru sonucunuz sms yoluyla size bildirilecektir.
                                </Text>
                            </div>
                        </div>
                    }
                </>
            }


        </section>
    )
}

export default OnboardingFormPage;