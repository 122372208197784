import React, {useRef} from 'react';
import {preventKey} from '../models/preventions';
import {FormItem} from "../models/types";
import ErrorMessage from './errorMessage';
import IndividualMessage from './individualMessage';

type InputSplitProps = {
    item: FormItem,
    changeFunction: Function
}

const InputSplit = (props: InputSplitProps) => {
    const inputs = useRef(new Array<HTMLInputElement>());

    let disabled: any = {};

    if (props.item.isDisabled) {
        disabled["disabled"] = "disabled";
    }

    const onKeyDown = (event: React.KeyboardEvent, index: number) => {
        const key = event.key;
        var inputValue = inputs.current[index].value;

        if (key === "ArrowLeft") {
            const newIndex = index - 1;
            if (newIndex >= 0) {
                inputs.current[newIndex].focus();
                inputs.current[newIndex].selectionStart = 1;
                inputs.current[newIndex].selectionEnd = 1;
                event.preventDefault();
            }
        } else if (key === "ArrowRight") {
            const newIndex = index + 1;
            if (newIndex < props.item.customValue.charCount) {
                inputs.current[newIndex].focus();
                inputs.current[newIndex].selectionStart = 1;
                inputs.current[newIndex].selectionEnd = 1;
                event.preventDefault();
            }
        } else if (key === "Backspace") {
            const newIndex = index - 1;
            if (inputValue === "" && newIndex >= 0) {
                inputs.current[newIndex].focus();
                inputs.current[newIndex].selectionStart = 1;
                inputs.current[newIndex].selectionEnd = 1;
            }
        }
    }

    const onChange = (value: string, index: number) => {
        let currentValue = "";
        for (var i = 0; i < inputs.current.length; i++) {
            currentValue += inputs.current[i].value;
        }

        if (value) {
            const newIndex = index + 1;
            if (newIndex < props.item.customValue.charCount) {
                inputs.current[newIndex].focus();
            }
        }

        props.changeFunction(currentValue, props.item.name);
    }

    let items = [];
    for (let i = 0; i < props.item.customValue.charCount; i++) {
        items.push(<input
            ref={e => {
                if (e) {
                    inputs.current[i] = e
                }
            }}
            className={"button s1"}
            maxLength={1}
            type="text"
            defaultValue={props.item.value.substr(i, 1)}
            onKeyPress={(e) => preventKey(e, props.item.prevention)}
            onKeyDown={(e) => onKeyDown(e, i)}
            onChange={(e) => onChange(e.target.value, i)} key={"input-split-" + i}
            {...disabled}
        />);
    }
    return (
        <>
            <div
                className={"form-item" + (props.item.isValid === false ? " has-error" : "") + (props.item.classNames ? " " + props.item.classNames : "")}>
                <div className="split-container">
                    <label className="button s2">{props.item.label}</label>
                    {items}
                </div>
                <ErrorMessage rules={props.item.rules}/>
                <IndividualMessage rules={props.item.rules}/>
            </div>
        </>
    )
}

export default InputSplit;