import {useAppDispatch} from '../../../store/hooks'
import {hideMessage} from '../../../store/globalSlice'
import './popup.scss';
import Title from '../../modules/title';
import Text from '../../modules/text';
import Image from '../../modules/image';
import {useEffect} from 'react';
import {alca} from '../../../alca/alca';

type PopupProps = {
    title?: string,
    text?: any,
    image?: string,
    isMobileFull?: boolean,
    btnPrimaryText?: string,
    btnPrimaryClassNames?: string,
    btnPrimaryCallback?: Function,
    btnSecondaryText?: string,
    btnSecondaryCallback?: Function,
    btnCloseCallback?: Function,
    classNames?: string
}

const Popup = (props: PopupProps) => {
    var dispatch = useAppDispatch();

    useEffect(() => {
        document.documentElement.scrollTo(0, 0);
        alca.scroll.lock(true);

        return () => {
            alca.scroll.lock(false);
        }
    }, []);

    const btnPrimaryClick = (event: React.MouseEvent) => {
        if (props.btnPrimaryCallback) {
            props.btnPrimaryCallback(event);
        } else {
            dispatch(hideMessage());
        }
    }

    const btnSecondaryClick = (event: React.MouseEvent) => {
        if (props.btnSecondaryCallback) {
            props.btnSecondaryCallback(event);
        }
    }

    const btnCloseClick = (event: React.MouseEvent) => {
        if (props.btnCloseCallback) {
            props.btnCloseCallback(event);
        } else {
            dispatch(hideMessage());
        }
    }

    return (
        <div
            className={"popup-wrapper" + (props.isMobileFull ? " full" : "") + (props.classNames ? " " + props.classNames : "")}>
            <div className="popup-container">
                <Image classNames="image" src={props.image}></Image>
                <Title size={4}>
                    {props.title}
                </Title>
                <Text size={2}>
                    {props.text}
                </Text>
                {props.btnPrimaryText &&
                    <button className={"btn large " + (props.btnPrimaryClassNames ?? "")} type="button"
                            title={props.btnPrimaryText}
                            onClick={(event) => btnPrimaryClick(event)}>{props.btnPrimaryText}</button>
                }
                {props.btnSecondaryText &&
                    <button className="btn large tertiary no-border blue" type="button" title={props.btnSecondaryText}
                            onClick={(event) => btnSecondaryClick(event)}>{props.btnSecondaryText}</button>
                }
            </div>
        </div>
    )
}

export default Popup;