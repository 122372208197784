type PreTitleProps = {
    classNames?: string,
    children?: React.ReactNode
}

const PreTitle = (props: PreTitleProps) => {
    if (props.children) {
        return (
            <div className={"pre-title " + props.classNames ?? ""}>{props.children}</div>
        )
    }

    return (null);
}

export default PreTitle;