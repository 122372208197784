type ImageProps = {
    src?: string,
    altText?: string,
    classNames?: string,
    containerClass?: string
}

const Image = (props: ImageProps) => {
    if (props.src && props.src.length > 0) {
        if (props.containerClass && props.containerClass.length > 0) {
            return (
                <div className={props.containerClass}>
                    <img className={props.classNames ?? ""} src={props.src} alt={props.altText}/>
                </div>
            )
        } else {
            return (
                <img className={props.classNames ?? ""} src={props.src} alt={props.altText}/>
            )
        }
    }

    return (null);
}

export default Image;