import {FormItemOption} from "../components/form/models/types";
import naceCodeJson from '../assets/meslek_nace_code.json';

export function convertToNaceCode(): Array<FormItemOption> {
    let naceCodes = Array<FormItemOption>()
    naceCodeJson.forEach(item => {
        let naceCode = item["NACE KODU"]
        let sectorName = item["MESLEK GURUP ADI"]
        let sectorDescription = item["NACE FAALİYET ADI"]

        let fullName = `${naceCode} - ${sectorName},  ${sectorDescription}\n`
        naceCodes.push({value: fullName, label: fullName})
    })
    return naceCodes;
}
