import {useEffect, useState} from "react";
import {
    companyAddressChange,
    companyIbanChange,
    companyOtpRequest,
    getCompanyProfile,
    otpValidate
} from "../../../actions/dashboardActions";
import {getCities, getCounties, getDistricts} from "../../../actions/globalActions";
import Sms from "../../../components/common/smsconfirm";
import Form from "../../../components/form";
import {FormItem, FormItemOption, FormItemType, FormModel, ValidationType} from "../../../components/form/models/types";
import Title from "../../../components/modules/title";
import {getCompanyInfo, setCompanyInfo} from "../../../store/globalSlice";
import {useAppSelector} from "../../../store/hooks";
import store from "../../../store/store";
import './company.scss';
import CookieCommonConsent from "../../../components/common/cookie-consent";


const DashboardCompany = () => {
    const companyInfo = useAppSelector(getCompanyInfo);
    const [confirmPopup, setConfirmPopup] = useState("");
    const formCompanyModel: FormModel = {
        items: [
            {
                type: FormItemType.CustomHtml,
                name: "",
                value:
                    <Title size={5} key="company-title">
                        Şirket Türü
                    </Title>
            },
            {
                type: FormItemType.Radio,
                name: "companyType",
                value: "",
                label: "",
                isDisabled: true,
                options: [
                    {label: 'Limited Şirket', value: 0, selected: (companyInfo?.companyTypeCode === 0)},
                    {label: 'Şahıs Şirketi', value: 1, selected: (companyInfo?.companyTypeCode === 1)},
                    {label: 'Anonim Şirket', value: 2, selected: (companyInfo?.companyTypeCode === 2)}]
            },
            {
                type: FormItemType.CustomHtml,
                name: "",
                value: <div className="spacer" key="spacer"></div>
            },
            {
                type: FormItemType.CustomHtml,
                name: "",
                value:
                    <Title size={5} key="commercial-title">
                        Ticari Bilgiler
                    </Title>
            },
            {
                type: FormItemType.Text,
                name: "taxNumber",
                value: companyInfo?.taxNumber,
                label: "Vergi Kimlik Numarası*",
                isDisabled: true,
                rules: [
                    {
                        type: ValidationType.TaxNo,
                        message: "*Tam olarak 10 rakam girmeniz gerekmektedir",
                        isValid: true
                    },
                ]
            },
            {
                type: FormItemType.Text,
                name: "companyTitle",
                value: companyInfo?.companyName,
                label: "Yasal Şirket Ünvanı*",
                isDisabled: true
            },
            {
                type: FormItemType.Text,
                name: "iban",
                value: companyInfo?.iban,
                label: "IBAN*",
                mask: "TR999999999999999999999999",
                isDisabled: true,
                rules: [
                    {
                        type: ValidationType.IBAN,
                        message: "*Geçerli bir IBAN giriniz",
                        isValid: true
                    }
                ]
            },
            {
                type: FormItemType.Button,
                name: "button",
                label: "IBAN Bilgimi Güncelle",
                classNames: "large",
                value: "btn-company-otp",
                changeFunction: () => {
                    companyOtpRequest({
                        source: "ibanUpdate"
                    }, (response: any) => {
                        formSmsModel = {
                            items: [
                                {
                                    type: FormItemType.SplitInput,
                                    name: "SmsCode",
                                    value: "",
                                    label: "6 haneli doğrulama kodunu girin",
                                    customValue: {charCount: 6, cursorPosition: 0},
                                    rules: [{type: ValidationType.Required}, {
                                        type: ValidationType.ExactLength,
                                        value: 6
                                    }],
                                    changeFunction: (item: FormItem) => {
                                        if (item.value.length === item.customValue.charCount) {
                                            otpValidate({
                                                email: companyInfo?.email,
                                                otp: item.value + "",
                                                phoneNumber: response.payload,
                                                source: "ibanUpdate"
                                            }, (response: any) => {
                                                if (response.success) {
                                                    let iban = formCompanyModel.items.find(x => x.name === "iban");
                                                    let btnOtp = formCompanyModel.items.find(x => x.value === "btn-company-otp");
                                                    let btnSubmit = formCompanyModel.items.find(x => x.value === "btn-company-submit");

                                                    if (iban) {
                                                        iban.isDisabled = false;
                                                    }

                                                    if (btnOtp) {
                                                        btnOtp.classNames = "large hidden";
                                                    }

                                                    if (btnSubmit) {
                                                        btnSubmit.classNames = "large";
                                                    }

                                                    setFormCompany({...formCompanyModel});

                                                    setConfirmPopup("");
                                                }
                                            });
                                        }
                                    }
                                },
                                {
                                    type: FormItemType.CustomHtml,
                                    name: "",
                                    value: <div className="sms-desc"><b>{response.payload}</b> numaralı telefona
                                        gönderilen 6 haneli kodu girin.</div>
                                }
                            ]
                        }
                        setFormSms(formSmsModel);
                        setConfirmPopup("iban");
                    });
                }

            },
            {
                type: FormItemType.Button,
                name: "submit",
                label: "IBAN Bilgimi Güncelle",
                classNames: "large hidden",
                value: "btn-company-submit"
            }
        ]
    };

    const formCompanySubmitFunction = (model: any) => {
        model.email = companyInfo?.email;

        companyIbanChange(model, () => {
            let iban = formCompanyModel.items.find(x => x.name === "iban");
            let btnOtp = formCompanyModel.items.find(x => x.value === "btn-company-otp");
            let btnSubmit = formCompanyModel.items.find(x => x.value === "btn-company-submit");

            if (iban) {
                iban.isDisabled = true;
            }

            if (btnOtp) {
                btnOtp.classNames = "large";
            }

            if (btnSubmit) {
                btnSubmit.classNames = "large hidden";
            }

            setFormCompany({...formCompanyModel});

            getCompanyProfile((response: any) => {
                store.dispatch(setCompanyInfo(response.payload));
            });
        });
    }

    const formAddressModel: FormModel = {
        items: [
            {
                type: FormItemType.CustomHtml,
                name: "",
                value:
                    <Title size={5} key="address-title">
                        Adres Bilgileri
                    </Title>
            },
            {
                type: FormItemType.Select,
                name: "cityCode",
                value: companyInfo?.cityCode,
                label: "İl*",
                isDisabled: true,
                rules: [
                    {type: ValidationType.Required}
                ],
                changeFunction: (item: FormItem) => {
                    var countySelect = formAddressModel.items.find(x => x.name === "countyCode");
                    var districtSelect = formAddressModel.items.find(x => x.name === "districtCode");

                    getCounties(item.value, ((options: Array<FormItemOption>) => {
                        if (districtSelect) {
                            districtSelect.value = "";
                        }

                        if (countySelect) {
                            countySelect.value = "";
                            countySelect.options = options;
                            countySelect.isDisabled = false;

                            setFormAddress({...formAddressModel});
                        }
                    }));
                }
            },
            {
                type: FormItemType.Select,
                name: "countyCode",
                value: companyInfo?.countyCode,
                label: "İlçe*",
                isDisabled: true,
                rules: [
                    {type: ValidationType.Required}
                ],
                changeFunction: (item: FormItem) => {
                    var districtSelect = formAddressModel.items.find(x => x.name === "districtCode");

                    getDistricts(item.value, ((options: Array<FormItemOption>) => {
                        if (districtSelect) {
                            districtSelect.options = options;
                            districtSelect.isDisabled = false;

                            setFormAddress({...formAddressModel});
                        }
                    }));
                }
            },
            {
                type: FormItemType.Select,
                name: "districtCode",
                value: companyInfo?.districtCode,
                label: "Semt*",
                isDisabled: true,
                rules: [
                    {type: ValidationType.Required}
                ]
            },
            {
                type: FormItemType.Text,
                name: "openAddress",
                value: companyInfo?.openAddress,
                label: "Adres*",
                isDisabled: true,
                rules: [
                    {type: ValidationType.Required},
                    {type: ValidationType.MinLength, value: 10},
                    {type: ValidationType.MaxLength, value: 1500}
                ]
            },
            {
                type: FormItemType.Button,
                name: "button",
                label: "Adres Bilgimi Güncelle",
                classNames: "large",
                value: "btn-address-otp",
                changeFunction: () => {
                    companyOtpRequest({
                        source: "addressUpdate"
                    }, (response: any) => {
                        formSmsModel = {
                            items: [
                                {
                                    type: FormItemType.SplitInput,
                                    name: "SmsCode",
                                    value: "",
                                    label: "6 haneli doğrulama kodunu girin",
                                    customValue: {charCount: 6, cursorPosition: 0},
                                    rules: [{type: ValidationType.Required}, {
                                        type: ValidationType.ExactLength,
                                        value: 6
                                    }],
                                    changeFunction: (item: FormItem) => {
                                        if (item.value.length === item.customValue.charCount) {
                                            otpValidate({
                                                email: companyInfo?.email,
                                                otp: item.value + "",
                                                phoneNumber: response.payload,
                                                source: "addressUpdate"
                                            }, (response: any) => {
                                                if (response.success) {
                                                    let btnOtp = formAddressModel.items.find(x => x.value === "btn-address-otp");
                                                    let btnSubmit = formAddressModel.items.find(x => x.value === "btn-address-submit");
                                                    let citySelect = formAddressModel.items.find(x => x.name === "cityCode");
                                                    let countySelect = formAddressModel.items.find(x => x.name === "countyCode");
                                                    let districtSelect = formAddressModel.items.find(x => x.name === "districtCode");
                                                    let address = formAddressModel.items.find(x => x.name === "openAddress");

                                                    if (citySelect) {
                                                        citySelect.isDisabled = false;
                                                    }

                                                    if (countySelect) {
                                                        countySelect.isDisabled = false;
                                                    }

                                                    if (districtSelect) {
                                                        districtSelect.isDisabled = false;
                                                    }

                                                    if (address) {
                                                        address.isDisabled = false;
                                                    }

                                                    if (btnOtp) {
                                                        btnOtp.classNames = "large hidden";
                                                    }

                                                    if (btnSubmit) {
                                                        btnSubmit.classNames = "large";
                                                    }

                                                    setFormAddress({...formAddressModel});

                                                    setConfirmPopup("");
                                                }
                                            });
                                        }
                                    }
                                },
                                {
                                    type: FormItemType.CustomHtml,
                                    name: "",
                                    value: <div className="sms-desc"><b>{response.payload}</b> numaralı telefona
                                        gönderilen 6 haneli kodu girin.</div>
                                }
                            ]
                        }
                        setFormSms(formSmsModel);
                        setConfirmPopup("address");
                    });
                }
            },
            {
                type: FormItemType.Button,
                name: "submit",
                label: "Adres Bilgimi Güncelle",
                classNames: "large hidden",
                value: "btn-address-submit"
            }
        ]
    };

    const formAddressSubmitFunction = (model: any) => {
        model.email = companyInfo?.email;
        model.taxBoardFtpUrl = "ftp://user:password@host:port/path";

        companyAddressChange(model, () => {
            let btnOtp = formAddressModel.items.find(x => x.value === "btn-address-otp");
            let btnSubmit = formAddressModel.items.find(x => x.value === "btn-address-submit");
            let citySelect = formAddressModel.items.find(x => x.name === "cityCode");
            let countySelect = formAddressModel.items.find(x => x.name === "countyCode");
            let districtSelect = formAddressModel.items.find(x => x.name === "districtCode");
            let address = formAddressModel.items.find(x => x.name === "openAddress");

            if (citySelect) {
                citySelect.isDisabled = true;
            }

            if (countySelect) {
                countySelect.isDisabled = true;
            }

            if (districtSelect) {
                districtSelect.isDisabled = true;
            }

            if (address) {
                address.isDisabled = true;
            }

            if (btnOtp) {
                btnOtp.classNames = "large";
            }

            if (btnSubmit) {
                btnSubmit.classNames = "large hidden";
            }

            setFormAddress({...formAddressModel});

            getCompanyProfile((response: any) => {
                store.dispatch(setCompanyInfo(response.payload));
            });
        });
    }

    let formSmsModel: FormModel = {
        items: [
            {
                type: FormItemType.SplitInput,
                name: "SmsCode",
                value: "",
                label: "6 haneli doğrulama kodunu girin",
                customValue: {charCount: 6, cursorPosition: 0},
                rules: [{type: ValidationType.Required}, {type: ValidationType.ExactLength, value: 6}],
                changeFunction: (item: FormItem) => {
                    if (item.value.length === item.customValue.charCount) {
                        otpValidate({
                            email: companyInfo?.email,
                            otp: item.value + "",
                            phoneNumber: companyInfo?.mobilePhone,
                            source: "addressUpdate"
                        }, (response: any) => {
                            setConfirmPopup("");
                        });
                    }
                }
            },
            {
                type: FormItemType.CustomHtml,
                name: "",
                value: <div className="sms-desc"><b>+90 505 511 11 11</b> numaralı telefona gönderilen 6 haneli kodu
                    girin.</div>
            }
        ]
    }

    useEffect(() => {
        if (companyInfo) {
            let citySelect = formAddressModel.items.find(x => x.name === "cityCode");
            let countySelect = formAddressModel.items.find(x => x.name === "countyCode");
            let districtSelect = formAddressModel.items.find(x => x.name === "districtCode");

            getCities((options: Array<FormItemOption>) => {
                if (citySelect) {
                    citySelect.options = options;

                    setFormAddress({...formAddressModel});
                }
            });
            // eslint-disable-next-line react-hooks/exhaustive-deps

            if (companyInfo.cityCode) {
                getCounties(companyInfo.cityCode, (options: Array<FormItemOption>) => {
                    if (countySelect) {
                        countySelect.options = options;

                        setFormAddress({...formAddressModel});
                    }
                });
                // eslint-disable-next-line react-hooks/exhaustive-deps
            }

            if (companyInfo.countyCode) {
                getDistricts(companyInfo.countyCode, (options: Array<FormItemOption>) => {
                    if (districtSelect) {
                        districtSelect.options = options;

                        setFormAddress({...formAddressModel});
                    }
                });
                // eslint-disable-next-line react-hooks/exhaustive-deps
            }

            setFormCompany(formCompanyModel);
            setFormAddress(formAddressModel);
            setFormSms(formSmsModel);
        }
    }, [companyInfo]);

    const [formCompany, setFormCompany] = useState<FormModel | null>(null);
    const [formAddress, setFormAddress] = useState<FormModel | null>(null);
    const [formSms, setFormSms] = useState<FormModel | null>(null);

    const onSmsReSendClick = () => {
        if (confirmPopup == "address") {
            let btnOtp = formAddressModel.items.find(x => x.value === "btn-address-otp");

            if (btnOtp && btnOtp.changeFunction) {
                btnOtp.changeFunction();
            }
        } else if (confirmPopup == "iban") {
            let btnOtp = formCompanyModel.items.find(x => x.value === "btn-company-otp");

            if (btnOtp && btnOtp.changeFunction) {
                btnOtp.changeFunction();
            }
        }
    }

    const btnConfirmCloseClick = (event: React.MouseEvent) => {
        setConfirmPopup("");
    }

    return (
        <>
            <Title size={4}>
                Şirket Bilgilerim
            </Title>
            <div className="dashboard-form">
                {formCompany !== null &&
                    <div className="form" key={"dashboard-form-company"}>
                        <Form formModel={formCompany} submitFunction={formCompanySubmitFunction}/>
                    </div>
                }
                {formAddress !== null &&
                    <div className="form" key={"dashboard-form-address"}>
                        <Form formModel={formAddress} submitFunction={formAddressSubmitFunction}/>
                    </div>
                }
            </div>
            {confirmPopup.length > 0 && formSms !== null &&
                <div className="popup-wrapper confirm">
                    <div className="popup-container">
                        <Sms title="SMS Onay" model={formSms} countdownMiliSeconds={180000}
                             btnReSendActivateTime={60000} btnReSendCallback={onSmsReSendClick}
                             btnBackCallback={btnConfirmCloseClick}/>
                    </div>
                </div>
            }


        </>
    )
}

export default DashboardCompany;