import React, {useRef, useState} from "react";
import Image from "../../modules/image";
import {FormItem} from "../models/types";
import ErrorMessage from './errorMessage';
import IndividualMessage from './individualMessage';

type FileUploadProps = {
    item: FormItem,
    changeFunction: Function
}

const FileUpload = (props: FileUploadProps) => {
    const [imageData, setImageData] = useState("");
    const input = useRef<HTMLInputElement>();
    const reader = new FileReader();
    let disabled: any = {};
    const [fileNames, setFileNames] = useState<string []>([]);
    const [currentFiles, setCurrentFiles] = useState<FileList | null>();

    if (props.item.isDisabled) {
        disabled["disabled"] = "disabled";
    }

    reader.addEventListener("load", function () {
        setImageData(reader.result as string);
    }, false);

    let btnUploadFilesClick = (e: React.MouseEvent) => {
        input.current?.click();

    }

    let btnRemoveFilesClick = (e: React.MouseEvent) => {
        props.changeFunction("", props.item.name);

        props.item.rules?.forEach(rule => {
            rule.isValid = false
        })
        props.item.isValid = false

        if (input && input.current) {
            input.current.value = "";
            setImageData("");
            setCurrentFiles(null);
        }
    }

    let fileInputChanged = (e: React.FormEvent<HTMLInputElement>) => {
        const newFiles = e.currentTarget.files;

        const fileList = [...(currentFiles ?? []), ...(newFiles ?? [])]

        const convertedFilesList = new DataTransfer();
        fileList.forEach((file) => {
            convertedFilesList.items.add(file);
        });
        setCurrentFiles(convertedFilesList.files);

        props.changeFunction(convertedFilesList.files, props.item.name);

        if (input && input.current && input.current.files && input.current.files.length > 0) {
            reader.readAsDataURL(input.current.files[0]);
        } else {
            if (input && input.current) {
                input.current.value = "";
                setImageData("");
                setCurrentFiles(null);
            }
        }

        showTheFilesAndAlsoShowTheTotalNumberIfMoreThanFour(convertedFilesList.files);
    }

    let showTheFilesAndAlsoShowTheTotalNumberIfMoreThanFour = (currentFiles: FileList | null) => {
        if (currentFiles != null) {
            let fileNames: any = []
            for (let i = 0; i < currentFiles!.length; i++) {
                if (currentFiles && i < 5) {
                    if (currentFiles[i].name.length > 34) {
                        const tempArray = currentFiles[i].name.split(".");

                        const newCurrentFileName = currentFiles[i].name.substr(0, 25).concat("... .");
                        const ext = tempArray[tempArray.length - 1];

                        fileNames.push(newCurrentFileName.concat(ext));
                    } else {
                        fileNames.push(currentFiles[i].name);
                    }
                }
            }
            if (currentFiles.length >= 5) {
                fileNames.push("...");
                fileNames.push("Toplam dosya sayısı " + currentFiles.length);
            }
            setFileNames(fileNames);
        }
    }

    return (
        <div
            className={"form-item file" + ((props.item.value.toString()).length > 0 ? " active" : "") + (props.item.isValid === false ? " has-error" : "") + (props.item.classNames ? " " + props.item.classNames : "")}>
            <label className="button s1">{props.item.label}</label>
            <input
                id={"fileUploader"}
                ref={input}
                type="file"
                accept=".pdf,.png,.jpeg"
                name={props.item.name}
                defaultValue={props.item.value}
                onChange={fileInputChanged}
                {...disabled}
                multiple={true}
            />
            <div className="image" onClick={btnUploadFilesClick}>
                {imageData.length > 0 ?
                    <div style={{height: '120px', width: '100%', fontSize: '0.12rem', color: 'black'}}>
                        <ul>{fileNames.map((item, i) => {
                            return (
                                <li>{item}</li>
                            );
                        })}</ul>
                    </div>
                    :
                    <Image src={require("../../../assets/Icons/file-placeholder.svg").default}/>
                }
            </div>
            <div className="file-buttons">
                {imageData.length > 0 ?
                    <>
                        <button className="btn small tertiary no-bg btn-remove" type="button" title="Sil"
                                onClick={btnRemoveFilesClick}>Sil
                        </button>
                        <button className="btn small tertiary no-bg blue" type="button" title="Tekrar Yükle"
                                onClick={btnUploadFilesClick}>Tekrar Yükle
                        </button>
                    </>
                    :
                    <button className="btn small" type="button" title="Belge Yükle" onClick={btnUploadFilesClick}>Belge
                        Yükle</button>
                }
            </div>
            <ErrorMessage rules={props.item.rules}/>
            <IndividualMessage rules={props.item.rules}/>
        </div>
    )
}

export default FileUpload;