import Content from '../../../components/common/content';
import CookieCommonConsent from "../../../components/common/cookie-consent";

const OnboardingPage = () => {
    return (
        <>
            <Content
                title="Üye İş Yeri Başvuru  Bilgi Ekranı"
                text="Üye işyeri başvurunuzu tamamlamak için aşağıdaki 4 aşamayı eksiksiz olarak tamamlamanız gerekmektedir. Her bir adım için gerekli olan bilgi ve belgeleri forma başlamadan önce temin etmenizi önermekteyiz."
                image={require('../../../assets/Uploads/onboarding.jpg')}
                list={[
                    {title: "Yetkili  Bilgileri", text: "Tüzel Kişi Ünvanı, Ad Soyad, E-Posta, TC Kimlik Numarası"},
                    {
                        title: "Ticari  Bilgiler ",
                        text: "Şirket Türü , Vergi Kimlik No, Faaliyet Alanı, IBAN, Adres Bilgisi"
                    },
                    {
                        title: "Belge Yükle",
                        text: "Vergi Levhası, Kimlik Fotokopisi, İmza Sirküleri, IBAN Kanıt Belgesi(Hesap cüzdan resmi veya mobil uygulama ekran görüntüsü)"
                    },
                    {
                        title: "Kimlik Doğrulama",
                        text: "YemeksepetiPay A.Ş'ye üye olmak isteyen iş yerleri temsilcilerinin, video çekerek canlılık testi şartını gerçekleştirmelidir. Bu aşamada video yükleme alanından Selfie Videonuzu çekmeniz gerekmektedir."
                    }
                ]}
                btnPrimaryText="Hemen Başla"
                btnPrimaryLink="/onboarding-form"
            />


        </>
    )
}

export default OnboardingPage;