import {FormItem, FormItemOption} from "../models/types";
import Select from 'react-select'
import ErrorMessage from "./errorMessage";
import {useState} from "react";

type DropdownProps = {
    item: FormItem,
    changeFunction: Function
}

const Dropdown = (props: DropdownProps) => {
    const [isActive, setActive] = useState(false);

    return (
        <div
            className={"form-item" + (props.item.value.toString().length > 0 ? " active" : "") + (props.item.isValid === false ? " has-error" : "") + (props.item.classNames ? " " + props.item.classNames : "")}
            onFocusCapture={() => {
                setActive(true)
            }}
            onBlurCapture={() => {
                setActive(false)
            }}
        >
            <Select
                className={"select-container " + (isActive ? "active" : "")}
                classNamePrefix="select"
                value={props.item.value !== "" ? props.item.options?.find((item: FormItemOption) => item.value === props.item.value) : null}
                options={props.item.options}
                filter={false}
                onChange={(e) => {
                    props.changeFunction(e?.value, props.item.name);
                }}
                isDisabled={props.item.isDisabled ? true : false}
            />
            <label className="button s2">{props.item.label}</label>
            <ErrorMessage rules={props.item.rules}/>
        </div>
    )
}

export default Dropdown;