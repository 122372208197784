import {useState} from 'react';
import './price.scss';
import Title from '../../modules/title';
import Text from '../../modules/text';
import Table from '../../../components/modules/table';
import CookieCommonConsent from "../cookie-consent";

const Price = () => {


    return (
        <section className="price">
            <div className="container large">
                <div className='banner'>
                    <img className='banner-img' src={require('../../../assets/Uploads/prices.png')}/>
                    <div className="banner-text tertiary">Ücretler & Limitler</div>
                </div>
                <div className="price-container">


                    <Title size={4}>
                        İşlem Ücreti ve Komisyon Bilgisi
                    </Title>

                    <Table
                        size="full"
                        title="YemeksepetiPay Cüzdan"
                        headers={["İşlem Adı", "Ücreti"]}
                        errorText="Bu alan şu anda görüntülenememektedir. Daha sonra tekrar deneyiniz."
                        items={[["Para Transfer Ücreti", "5,00 TL"], ["Bakiye Yükleme Ücreti", "Ücretsiz"]]}
                    />

                    <Table
                        size="full"
                        title="YemeksepetiPay Sanal POS"
                        headers={["İşlem Adı", "Ücreti"]}
                        errorText="Bu alan şu anda görüntülenememektedir. Daha sonra tekrar deneyiniz."
                        items={[["Azami Komisyon Oranı", "2 %"], ["İşlem Başı Ücret", "0,00 TL"], ["POS Bloke Gün Sayısı (Komisyonsuz)", "28 Gün"]]}
                    />

                    <Text size={2}>
                        <em>*Yukarıda görülen ücret ve komisyonlara BSMV dahil değildir. Elektronik para ve ödeme
                            kuruluşları, hizmetlerine karşılık tahsil ettiklerini
                            komisyon, ücret gelirlerinden % 5 oranında BSMV ye tabi olup, ilgili tutar komisyon / ücret
                            üzerinden ayrıca hesaplanıp tahsil edilmektedir. </em>
                    </Text>

                </div>
            </div>


        </section>
    )
}

export default Price;