import {useState} from 'react';
import {useAppDispatch} from '../../../store/hooks'
import {hideMessage, showMessage} from '../../../store/globalSlice'
import './contact.scss';
import Form from '../../form';
import {FormItem, FormItemType, FormModel, ValidationType} from '../../form/models/types';
import Title from '../../modules/title';
import Text from '../../modules/text';
import {useHistory} from 'react-router-dom';
import {contactNewUser} from '../../../actions/globalActions';
import CookieCommonConsent from "../cookie-consent";

const Contact = () => {
    var dispatch = useAppDispatch();
    let history = useHistory();
    const goToPreviousPath = () => {
        history.goBack()
    }

    const [formModel] = useState<FormModel>({
        items: [
            {
                type: FormItemType.Text,
                name: "fullName",
                value: "",
                label: "Ad Soyad",
                rules: [{type: ValidationType.Required}]
            },
            {
                type: FormItemType.Email,
                name: "email",
                value: "",
                label: "E-Posta",
                rules: [
                    {type: ValidationType.Required},
                    {type: ValidationType.Email, message: "*Lütfen geçerli bir e-posta giriniz."}
                ]
            },
            {
                type: FormItemType.Select,
                name: "title",
                value: "",
                label: "Konu Başlığı",
                options: [
                    {label: 'Başvuru', value: 'Başvuru'},
                    {label: 'Bilgi Değişikliği', value: 'Bilgi Değişikliği'},
                    {label: 'Ödemeler / Tahsilat', value: 'Ödemeler / Tahsilat'},
                    {label: 'Diğer', value: 'Diğer'}
                ]
            },
            {
                type: FormItemType.Textarea,
                name: "message",
                value: "",
                label: "Açıklama Giriniz",
                rules: [
                    {type: ValidationType.Required},
                    {
                        type: ValidationType.MaxLength,
                        value: 1500,
                        message: "*Maksimum 1500 karakter girmeniz gerekmektedir "
                    },
                    {type: ValidationType.MinLength, value: 30, message: "*Minimum 30 karakter girmeniz gerekmektedir "}
                ]
            },
            {
                type: FormItemType.Checkbox,
                name: "userConsent",
                value: "",
                isDisabled: false,
                changeFunction: (item: FormItem) => {
                    item.isDisabled = true
                    window.open("/aydinlatma-metni.pdf");
                },
                label: "Kişisel verileriniz, talebinizin gerçekleştirilmesi amacıyla 6698 Sayılı Kanun kapsamında işlenmektedir. KVKK Aydınlatma Metni'ni okudum ve kabul ediyorum.",
                classNames: "full",
                rules: [
                    {type: ValidationType.Required, message: "Üyeliğinizi tamamlayabilmek için kutucuğu işaretleyin"},
                ]
            },
            {
                type: FormItemType.CustomHtml,
                name: "",
                isValid: true,
                value: <div className="text s2">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; KVKK Aydınlatma Metni'ne <a href="/aydinlatma-metni.pdf"
                                                                                           title="KVKK Aydınlatma metni"
                                                                                           target="_blank"><b>buradan</b></a> ulaşabilirsiniz.
                </div>,
            },
            {
                type: FormItemType.Button,
                name: "submit",
                label: "Gönder",
                classNames: "large",
                value: "",
                rules: [{type: ValidationType.AllValidated}]
            },
            {
                type: FormItemType.Button,
                name: "button",
                label: "Geri",
                classNames: "tertiary blue no-border large",
                value: "",
                changeFunction: goToPreviousPath
            },
        ]
    });

    const formSubmitFunction = (model: any) => {
        contactNewUser(model, () => {
            var message = {
                classNames: "result",
                title: "İletişim Talebinizi Aldık",
                text: "İlgili birimlerimiz mesajınızı inceledikten sonra, en kısa sürede size dönüş sağlayacaktır.",
                image: require("../../../assets/Icons/contact-success.svg").default,
                btnPrimaryText: "Tamam",
                btnPrimaryCallback: () => {
                    dispatch(hideMessage());
                    history.push("/");
                },
                isMobileFull: true
            };

            dispatch(showMessage(message));

        }, () => {

        });
    }

    return (
        <section className="contact">
            <div className="container small">
                <div className="form">
                    <Title size={4}>
                        Bize Ulaşın
                    </Title>
                    <Text size={2}>
                        Aşağıdaki iletişim formunu doldurarak, <a href="mailto:destek@yemekpay.com"
                                                                  className="email-link">destek@yemekpay.com</a> adresine
                        e-posta göndererek veya 0212 274 18 00 numaralı çağrı merkezini arayarak bize ulaşabilirsiniz.
                        İlgili birimlerimiz mesajınızı inceledikten sonra, en kısa sürede size dönüş sağlayacaktır.
                    </Text>
                    <Form formModel={formModel} submitFunction={formSubmitFunction}/>
                </div>
            </div>


        </section>
    )
}

export default Contact;
