type TextProps = {
    size: number,
    classNames?: string,
    children?: React.ReactNode
}

const Text = (props: TextProps) => {
    if (props.children) {
        return (
            <div className={"text s" + props.size + " " + props.classNames ?? ""}>{props.children}</div>
        )
    }

    return (null);
}

export default Text;