import {AxiosModel, call} from './apiCaller';
import {format} from 'date-fns'
import tr from "date-fns/locale/tr";

export const getUserProfile = (successFunc: Function, errorFunc?: Function) => {
    let model: AxiosModel = {
        url: 'merchant/users',
        method: 'get',
        successFunc: successFunc,
        errorFunc: errorFunc,
        preventMessage: true
    }

    call(model);
}

export const getCompanyProfile = (successFunc: Function, errorFunc?: Function) => {
    let model: AxiosModel = {
        url: 'merchant/company-info',
        method: 'get',
        successFunc: successFunc,
        errorFunc: errorFunc,
        preventMessage: true
    }

    call(model);
}

export const getFailedLogins = (params: object, successFunc: Function, errorFunc?: Function) => {
    let model: AxiosModel = {
        url: 'merchant/login-attempts',
        method: 'get',
        params: params,
        errorFunc: errorFunc,
        preventMessage: true
    }

    model.successFunc = (response: any) => {
        let transactions = response.payload.transactions;
        let arr: Array<Array<string>> = [];

        if (transactions && transactions.length > 0) {
            transactions.map((item: any) => {
                arr.push([format(new Date(item.dateTime), "dd MMMM yyyy, HH:mm", {locale: tr})]);
            });
        }

        successFunc(arr);
    }

    call(model);
}

export const getTransactions = (params: object, successFunc: Function, errorFunc?: Function) => {
    let model: AxiosModel = {
        url: 'account/statement',
        method: 'get',
        params: params,
        errorFunc: errorFunc,
        preventMessage: true
    }

    model.successFunc = (response: any) => {
        let transactions = response.data.activity?.transactions;
        let arr: Array<Array<string>> = [];

        if (transactions && transactions.length > 0) {
            transactions.map((item: any) => {
                arr.push([format(new Date(item.documentDate), "dd MMMM yyyy, HH:mm", {locale: tr}), item.referenceId, item.amount + " TL", item.type]);
            });
        }

        successFunc(arr, response.data.accountBalance);
    }

    call(model);
}

export const payoutRequest = (data: object, successFunc?: Function, errorFunc?: Function) => {
    let model: AxiosModel = {
        url: 'account/payout',
        method: 'post',
        data: data,
        errorFunc: errorFunc,
        successFunc: successFunc
    }

    call(model);
}

export const contactDashboard = (data: object, successFunc?: Function, errorFunc?: Function) => {
    let model: AxiosModel = {
        url: 'merchant/customer-contact',
        method: 'post',
        data: data,
        errorFunc: errorFunc,
        successFunc: successFunc
    }

    call(model);
}

export const changePassword = (data: object, successFunc?: Function, errorFunc?: Function) => {
    let model: AxiosModel = {
        url: 'merchant/change-password',
        method: 'post',
        data: data,
        errorFunc: errorFunc,
        successFunc: successFunc
    }

    call(model);
}

export const changePhone = (data: object, successFunc?: Function, errorFunc?: Function) => {
    let model: AxiosModel = {
        url: 'merchant/phone-number',
        method: 'put',
        data: data,
        errorFunc: errorFunc,
        successFunc: successFunc
    }

    call(model);
}

export const otpRequest = (data: object, successFunc?: Function, errorFunc?: Function) => {
    let model: AxiosModel = {
        url: 'merchant/sms-otp-request',
        method: 'post',
        data: data,
        errorFunc: errorFunc,
        successFunc: successFunc
    }

    call(model);
}

export const otpValidate = (data: object, successFunc?: Function, errorFunc?: Function) => {
    let model: AxiosModel = {
        url: 'merchant/sms-otp-validate',
        method: 'post',
        data: data,
        errorFunc: errorFunc,
        successFunc: successFunc
    }

    call(model);
}

export const companyOtpRequest = (data: object, successFunc?: Function, errorFunc?: Function) => {
    let model: AxiosModel = {
        url: 'company/update-check',
        method: 'post',
        data: data,
        errorFunc: errorFunc,
        successFunc: successFunc
    }

    call(model);
}

export const companyAddressChange = (data: object, successFunc?: Function, errorFunc?: Function) => {
    let model: AxiosModel = {
        url: 'company/address',
        method: 'put',
        data: data,
        errorFunc: errorFunc,
        successFunc: successFunc
    }

    call(model);
}

export const companyIbanChange = (data: object, successFunc?: Function, errorFunc?: Function) => {
    let model: AxiosModel = {
        url: 'company/iban',
        method: 'put',
        data: data,
        errorFunc: errorFunc,
        successFunc: successFunc
    }

    call(model);
}

export const uploadFiles = (data: object, successFunc?: Function, errorFunc?: Function) => {
    let model: AxiosModel = {
        url: 'merchant/file-upload',
        method: 'post',
        data: data,
        errorFunc: errorFunc,
        successFunc: successFunc
    }

    call(model);
}

export const createCompany = (data: object, successFunc?: Function, errorFunc?: Function) => {
    let model: AxiosModel = {
        url: 'company/create',
        method: 'post',
        data: data,
        errorFunc: errorFunc,
        successFunc: successFunc
    }

    call(model);
}