import {preventKey} from '../models/preventions';
import {FormItem} from "../models/types";
import ErrorMessage from './errorMessage';
import IndividualMessage from './individualMessage';
import InputMask from "react-input-mask";

type InputTextProps = {
    item: FormItem,
    changeFunction: Function
}

const InputText = (props: InputTextProps) => {
    let disabled: any = {};

    if (props.item.isDisabled) {
        disabled["disabled"] = "disabled";
    }

    return (
        <div
            className={"form-item" + ((props.item.value.toString()).length > 0 ? " active" : "") + (props.item.isValid === false ? " has-error" : "") + (props.item.classNames ? " " + props.item.classNames : "")}>
            {props.item.mask ?
                <InputMask
                    className="button s1"
                    type="text"
                    name={props.item.name}
                    defaultValue={props.item.value}
                    mask={props.item.mask}
                    onKeyPress={(e) => preventKey(e, props.item.prevention)}
                    onChange={(e) => {
                        props.changeFunction(e.target.value, props.item.name)
                    }}
                    {...disabled}
                />
                :
                <input
                    className="button s1"
                    type="text"
                    name={props.item.name}
                    defaultValue={props.item.value}
                    onKeyPress={(e) => preventKey(e, props.item.prevention)}
                    onChange={(e) => {
                        props.changeFunction(e.target.value, props.item.name)
                    }}
                    {...disabled}
                />
            }
            <label className="button s2">{props.item.label}</label>
            <ErrorMessage rules={props.item.rules}/>
            <IndividualMessage rules={props.item.rules}/>
        </div>
    )
}

export default InputText;