const html = document.documentElement;
const body = document.body;

type ResizeFunction = {
    key: string,
    func: Function
}

export const alca = {
    settings: {
        mobileChangeWidth: 900
    },
    scroll: {
        isLocked: false,
        lastPosition: 0,
        getBarWidth: () => {
            return window.innerWidth - body.clientWidth;
        },
        lock: (isLock: boolean) => {
            if (isLock && !alca.scroll.isLocked) {
                alca.scroll.isLocked = true;
                alca.scroll.lastPosition = html.scrollTop;
                body.style.paddingRight = alca.scroll.getBarWidth() + "px";
                body.style.marginTop = alca.scroll.lastPosition * (-1) + "px";
                html.classList.add("lock");
            } else if (!isLock && alca.scroll.isLocked) {
                alca.scroll.isLocked = false;
                body.style.paddingRight = "";
                body.style.marginTop = "";
                html.classList.remove("lock");
                html.scrollTo(0, alca.scroll.lastPosition);
            }
        }
    },
    resize: {
        width: 0,
        mobileChangeWidth: 900,
        isInit: false,
        isMobile: false,
        execute: function () {
            waitForFinalEvent(function () {
                var scrolled = (body.scrollTop / body.offsetHeight) * 100;

                alca.resize.refresh();

                //Only fire when width changes
                if (alca.resize.width !== window.innerWidth) {
                    alca.resize.width = window.innerWidth;
                    alca.f.repaintHtml();
                }
                ;

                window.scrollTo(0, (document.body.offsetHeight * scrolled) / 100);

            }, 250, "global resize");
        },
        //type: mobile / mobile_init / desktop / desktop_init / all
        init: function () {
            if (window.innerWidth + alca.scroll.getBarWidth() <= this.mobileChangeWidth) {
                this.isMobile = true;
            } else {
                this.isMobile = false;
            }

            this.width = window.innerWidth;

            window.addEventListener('resize', alca.resize.execute);
        },
        deinit: function () {
            window.removeEventListener('resize', alca.resize.execute);
        },
        set: function (func: Function, breakpoint: "mobile" | "mobile_init" | "desktop" | "desktop_init" | "all") {
            const key = alca.f.guid();
            const resizeFunc = {
                key: key,
                func: func
            };

            this.functions[breakpoint].push(resizeFunc);

            return key;
        },
        m: function (func: Function) {
            return this.set(func, "mobile");
        },
        minit: function (func: Function) {
            return this.set(func, "mobile_init");
        },
        d: function (func: Function) {
            return this.set(func, "desktop");
        },
        dinit: function (func: Function) {
            return this.set(func, "desktop_init");
        },
        all: function (func: Function) {
            return this.set(func, "all");
        },
        removeM: function (key: string) {
            this.functions["mobile"] = this.functions["mobile"].filter(item => item.key !== key);
        },
        removeMinit: function (key: string) {
            this.functions["mobile_init"] = this.functions["mobile_init"].filter(item => item.key !== key);
        },
        removeD: function (key: string) {
            this.functions["desktop"] = this.functions["desktop"].filter(item => item.key !== key);
        },
        removeDinit: function (key: string) {
            this.functions["desktop_init"] = this.functions["desktop_init"].filter(item => item.key !== key);
        },
        removeAll: function (key: string) {
            this.functions["all"] = this.functions["all"].filter(item => item.key !== key);
        },
        refresh: function () {
            setTimeout(function () {
                if (window.innerWidth + alca.scroll.getBarWidth() <= alca.resize.mobileChangeWidth) {
                    //Mobile Resize
                    if (!alca.resize.isMobile || !alca.resize.isInit) {
                        //Mobile init
                        alca.resize.isMobile = true;

                        for (let i = 0; i < alca.resize.functions.mobile_init.length; i++) {
                            alca.resize.functions.mobile_init[i].func();
                        }
                        ;

                        alca.resize.isInit = true;
                    }
                    ;

                    for (let i = 0; i < alca.resize.functions.mobile.length; i++) {
                        alca.resize.functions.mobile[i].func();
                    }
                    ;
                } else {
                    //Desktop Resize
                    if (alca.resize.isMobile || !alca.resize.isInit) {
                        //Desktop init
                        alca.resize.isMobile = false;

                        for (let i = 0; i < alca.resize.functions.desktop_init.length; i++) {
                            alca.resize.functions.desktop_init[i].func();
                        }
                        ;

                        alca.resize.isInit = true;
                    }
                    ;

                    for (let i = 0; i < alca.resize.functions.desktop.length; i++) {
                        alca.resize.functions.desktop[i].func();
                    }
                    ;
                }

                for (let i = 0; i < alca.resize.functions.all.length; i++) {
                    alca.resize.functions.all[i].func();
                }
                ;
            }, 0);
        },
        functions: {
            mobile: [] as Array<ResizeFunction>,
            mobile_init: [] as Array<ResizeFunction>,
            desktop: [] as Array<ResizeFunction>,
            desktop_init: [] as Array<ResizeFunction>,
            all: [] as Array<ResizeFunction>
        }
    },
    init: function () {
        alca.f.SetCssVariables();
        alca.resize.init();
        alca.resize.refresh();
    },
    deinit: function () {
        alca.resize.deinit();
    },
    f: {
        repaintHtml: function () {
            html.style.display = 'none';
            let offset = html.offsetHeight;
            html.style.display = '';

            return offset;
        },
        SetCssVariables: function () {
            alca.resize.all(() => {
                let vh = window.innerHeight * 0.01;
                document.documentElement.style.setProperty('--vh', `${vh}px`);
            });
        },
        guid: function () {
            function s4() {
                return Math.floor((1 + Math.random()) * 0x10000)
                    .toString(16)
                    .substring(1);
            }

            return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
                s4() + '-' + s4() + s4() + s4();
        }
    }
};

let waitForFinalEvent = (function () {
    let timers: any = {};
    return function (callback: Function, ms: number, uniqueId: string) {
        if (!uniqueId) {
            uniqueId = "Don't call this twice without a uniqueId";
        }
        if (timers[uniqueId]) {
            clearTimeout(timers[uniqueId]);
        }
        timers[uniqueId] = setTimeout(callback, ms);
    };
})();