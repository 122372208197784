import {useCallback, useEffect, useState} from "react";
import {changePassword, changePhone, otpRequest} from "../../../actions/dashboardActions";
import Form from "../../../components/form";
import {FormItem, FormItemType, FormModel, PreventionType, ValidationType} from "../../../components/form/models/types";
import Title from "../../../components/modules/title";
import Text from "../../../components/modules/text";
import {getUserInfo} from "../../../store/globalSlice";
import {useAppSelector} from "../../../store/hooks";
import Sms from "../../../components/common/smsconfirm";
import './profile.scss';
import CookieCommonConsent from "../../../components/common/cookie-consent";


const DashboardProfile = () => {
    const userInfo = useAppSelector(getUserInfo);
    const [isPhonePopupActive, setPhonePopup] = useState(false);
    const [newPhone, setNewPhone] = useState("");
    const [formSmsModel, setFormSmsModel] = useState<FormModel | null>(null);

    const [formProfileModel, setFormProfileModel] = useState<FormModel>({
        items: [
            {
                type: FormItemType.CustomHtml,
                name: "",
                value:
                    <Title size={5}>
                        Kullanıcı Bilgilerim
                    </Title>
            },
            {
                type: FormItemType.Text,
                name: "firstName",
                value: (userInfo ? userInfo.firstName : ""),
                label: "Yetkilinin Adı*",
                isDisabled: true
            },
            {
                type: FormItemType.Text,
                name: "lastName",
                value: (userInfo ? userInfo.lastName : ""),
                label: "Yetkilinin Soyadı*",
                isDisabled: true
            },
            {
                type: FormItemType.Text,
                name: "mobileNumberShow",
                value: (userInfo ? userInfo.mobileNumber : ""),
                label: "Yetkilinin Cep Telefonu*",
                isDisabled: true,
                mask: "+\\905999999999"
            },
            {
                type: FormItemType.Button,
                name: "submit",
                label: "Telefon Numaramı Güncelle",
                classNames: "large",
                value: ""
            }
        ]
    });

    const formProfileSubmitFunction = (model: any) => {
        setPhonePopup(true);
    };

    const [formPasswordModel] = useState<FormModel>({
        items: [
            {
                type: FormItemType.CustomHtml,
                name: "",
                value:
                    <Title size={5}>
                        Şifre Değişikliği
                    </Title>
            },
            {
                type: FormItemType.Password,
                name: "currentPassword",
                value: "",
                label: "Mevcut Şifre*",
                rules: [
                    {type: ValidationType.Required}
                ],
                prevention: {
                    type: PreventionType.Custom, rule: (event: React.KeyboardEvent) => {
                        if ("0123456789".indexOf(event.key) === -1 || (event.currentTarget as HTMLInputElement).value.length >= 6) {
                            event.preventDefault();
                            return false;
                        }

                        return true;
                    }
                }
            },
            {
                type: FormItemType.Password,
                name: "newPassword",
                value: "",
                label: "Şifre*",
                rules: [
                    {type: ValidationType.Required},
                    {type: ValidationType.ExactLength, value: 6, text: "6 adet rakamdan oluşmalı"},
                    {
                        type: ValidationType.Custom, text: "En az 3 farklı rakam içermeli", rule: (item: FormItem) => {
                            let count = 0;
                            (item.value + "").split('').forEach((char) => {
                                const l = item.value.match(new RegExp(char + "", "g")).length;
                                count += l > 1 ? 1 / l : 1;
                            });

                            return count >= 3;
                        }
                    },
                    {
                        type: ValidationType.Custom,
                        text: "En fazla 2 ardışık rakam yan yana bulunmalı",
                        rule: (item: FormItem) => {
                            if (item.value.length === 6) {
                                for (let i = 0; i < item.value.length - 2; i++) {
                                    if ("0123456789".indexOf(item.value.substr(i, 3)) !== -1) {
                                        return false;
                                    }
                                }

                                return true;
                            }

                            return false;
                        }
                    },
                    {
                        type: ValidationType.Custom,
                        text: "Aynı rakam arka arkaya en fazla 2 kez kullanılmalı",
                        rule: (item: FormItem) => {
                            return !(/(.)\1\1/.test(item.value));
                        }
                    },
                    {
                        type: ValidationType.Custom,
                        text: "Ardışık en fazla 2 rakam kullanılmalı",
                        rule: (item: FormItem) => {
                            if (item.value.length === 6) {
                                let count = 0;
                                for (let i = 0; i < item.value.length - 2; i++) {
                                    if ("0123456789".indexOf(item.value.substr(i, 2)) !== -1) {
                                        count++;
                                    }
                                }

                                return count < 2;
                            }

                            return false;
                        }
                    },
                    {
                        type: ValidationType.Custom,
                        text: "İlk 3 rakam ile son 3 rakam farklı olmalı",
                        rule: (item: FormItem) => {
                            return item.value.length === 6 && item.value.substr(3) !== item.value.substring(0, 3);
                        }
                    }
                ],
                prevention: {
                    type: PreventionType.Custom, rule: (event: React.KeyboardEvent) => {
                        if ("0123456789".indexOf(event.key) === -1 || (event.currentTarget as HTMLInputElement).value.length >= 6) {
                            event.preventDefault();
                            return false;
                        }

                        return true;
                    }
                }
            },
            {
                type: FormItemType.Password,
                name: "newPasswordConfirm",
                value: "",
                label: "Şifre Tekrar*",
                rules: [
                    {type: ValidationType.Required},
                    {
                        type: ValidationType.SameWith,
                        value: "newPassword",
                        text: "Girilen şifreler birbiri ile aynı olmalı"
                    },
                ],
                prevention: {
                    type: PreventionType.Custom, rule: (event: React.KeyboardEvent) => {
                        if ("0123456789".indexOf(event.key) === -1 || (event.currentTarget as HTMLInputElement).value.length >= 6) {
                            event.preventDefault();
                            return false;
                        }

                        return true;
                    }
                }
            },
            {
                type: FormItemType.Button,
                name: "submit",
                label: "Güncelle",
                classNames: "large",
                value: "",
                rules: [{type: ValidationType.AllValidated}]
            }
        ]
    });

    const formPasswordSubmitFunction = (model: any) => {
        changePassword(model);
    }

    const [formPhoneModel, setFormPhoneModel] = useState<FormModel>({
        items: [
            {
                type: FormItemType.Text,
                name: "phoneNumber",
                value: "",
                label: "Yetkilinin Cep Telefonu*",
                mask: "+\\905999999999",
                rules: [
                    {type: ValidationType.Required},
                    {
                        type: ValidationType.Custom, rule: (item: FormItem) => {
                            return item.value.replace(/_/g, "").length === 13;
                        }
                    }]
            },
            {
                type: FormItemType.Button,
                name: "submit",
                label: "Güncelle",
                classNames: "large",
                value: "",
                rules: [{type: ValidationType.AllValidated}]
            }
        ]
    });

    const formPhoneSubmitFunction = (model: any) => {
        setNewPhone(model);

        otpRequest({
            source: "changePhoneNumber",
            phoneNumber: model.phoneNumber
        }, (response: any) => {
            setPhonePopup(false);
            setFormSmsModel({
                items: [
                    {
                        type: FormItemType.SplitInput,
                        name: "SmsCode",
                        value: "",
                        label: "6 haneli doğrulama kodunu girin",
                        customValue: {charCount: 6, cursorPosition: 0},
                        rules: [{type: ValidationType.Required}, {type: ValidationType.ExactLength, value: 6}],
                        changeFunction: (item: FormItem) => {
                            if (item.value.length === item.customValue.charCount) {
                                changePhone({
                                    phoneNumber: model.phoneNumber,
                                    otp: item.value
                                }, () => {
                                    setFormSmsModel(null);
                                });
                            }
                        }
                    },
                    {
                        type: FormItemType.CustomHtml,
                        name: "",
                        value: <div className="sms-desc"><b>{model.phoneNumber}</b> numaralı telefona gönderilen 6
                            haneli kodu girin.</div>
                    }
                ]
            });
        });
    };

    useEffect(() => {
        if (userInfo) {
            var firstName = formProfileModel.items.find(p => p.name == "firstName");
            if (firstName) {
                firstName.value = userInfo.firstName;
            }
            var lastName = formProfileModel.items.find(p => p.name == "lastName");
            if (lastName) {
                lastName.value = userInfo.lastName;
            }
            var mobileNumber = formProfileModel.items.find(p => p.name == "mobileNumber");
            if (mobileNumber) {
                mobileNumber.value = userInfo.mobileNumber;
            }

            setFormProfileModel(formProfileModel);
        }
    }, [userInfo]);

    const btnPhoneCloseClick = (event: React.MouseEvent) => {
        setPhonePopup(false);
    }

    const btnConfirmCloseClick = (event: React.MouseEvent) => {
        setFormSmsModel(null);
    }

    const onSmsReSendClick = () => {
        formPhoneSubmitFunction(newPhone);
    }

    return (
        <>
            {userInfo &&
                <>
                    <Title size={4}>
                        Profil Bilgilerim
                    </Title>
                    <div className="dashboard-form">
                        <div className="form">
                            <Form key={"dashboard-form-profile"} formModel={formProfileModel}
                                  submitFunction={formProfileSubmitFunction}/>
                        </div>
                        <div className="form">
                            <Form key={"dashboard-form-password"} formModel={formPasswordModel}
                                  submitFunction={formPasswordSubmitFunction}/>
                        </div>
                    </div>
                    {isPhonePopupActive &&
                        <div className="popup-wrapper phone">
                            <div className="popup-container">
                                <Title size={4}>
                                    Yeni Cep Telefonunuz Giriniz
                                </Title>
                                <Text size={2}>
                                    Contrary to popular belief, Lorem Ipsum is not simply random text to popular belief.
                                    Classical Latin literature from. Lorem ipsum dolor sit
                                </Text>
                                <Form key={"dashboard-form-phone"} formModel={formPhoneModel}
                                      submitFunction={formPhoneSubmitFunction}/>
                                <div className="buttons">
                                    <button className="btn large tertiary blue no-bg no-border" type="button"
                                            title="Geri" onClick={(event) => btnPhoneCloseClick(event)}>Geri
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                    {formSmsModel &&
                        <div className="popup-wrapper confirm">
                            <div className="popup-container">
                                <Sms title="SMS Onay" model={formSmsModel} countdownMiliSeconds={180000}
                                     btnReSendActivateTime={60000} btnReSendCallback={onSmsReSendClick}
                                     btnBackCallback={btnConfirmCloseClick}/>
                            </div>
                        </div>
                    }
                </>
            }


        </>
    )
}

export default DashboardProfile;