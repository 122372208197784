import {preventKey} from '../models/preventions';
import {FormItem} from "../models/types";
import ErrorMessage from './errorMessage';
import IndividualMessage from './individualMessage';

type InputTextProps = {
    item: FormItem,
    changeFunction: Function
}

const InputEmail = (props: InputTextProps) => {
    let disabled: any = {};

    if (props.item.isDisabled) {
        disabled["disabled"] = "disabled";
    }

    return (
        <div
            className={"form-item" + (props.item.value.length > 0 ? " active" : "") + (props.item.isValid === false ? " has-error" : "") + (props.item.classNames ? " " + props.item.classNames : "")}>
            <input
                className="button s1"
                type="email"
                name={props.item.name}
                defaultValue={props.item.value}
                onKeyPress={(e) => preventKey(e, props.item.prevention)}
                onChange={(e) => {
                    props.changeFunction(e.target.value, props.item.name)
                }}
                {...disabled}
            />
            <label className="button s2">{props.item.label}</label>
            <ErrorMessage rules={props.item.rules}/>
            <IndividualMessage rules={props.item.rules}/>
        </div>
    )
}

export default InputEmail;