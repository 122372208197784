type TitleProps = {
    size: number,
    classNames?: string,
    children?: React.ReactNode
}

const Title = (props: TitleProps) => {
    if (props.children) {
        return (
            <div className={"title s" + props.size + " " + props.classNames ?? ""}>{props.children}</div>
        )
    }

    return (null);
}

export default Title;